/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, MenuItem, FormControl, InputLabel, Select, Checkbox, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllPackagesModule } from '../../../store/apps/paimentMethod/packModuleSlice';
import { updatePaymentById } from '../../../store/apps/paimentMethod/paymentSlice';

const UpdateTableInscriptionsAdmin = ({ open, packageData, onClose, onSave, id, formatDate }) => {
    // ------------- other imports -----------------------------------------
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(packageData.isActive);

    // ------------- END other imports -----------------------------------------
    // ------------------ USESELECOTRS ----------------
    const { moduleLists } = useSelector((state) => state.moduleListReducer);
    const { packagesModules, status, error } = useSelector((state) => state.packModuleReducer);

    const { users } = useSelector((state) => state.userReducer);
    const userId = users.id;
    console.log(moduleLists, "moduleLists");
    console.log(packagesModules, "packagesModules");
    // console.log(
    //     formData?.packModule?.map((pm) => pm.moduleId)
    // );
    // ------------------ END USESELECOTRS ----------------

    // ------------- useStates -----------------------------------------
    const [formData, setFormData] = useState({
        ...packageData,
        dateDebut: packageData.dateDebut || '',
        dateExp: packageData.dateExp || '',
        packNbrSite: packageData.packNbrSite || '',
        packRqtNumber: packageData.packRqtNumber || '',
        packModule: packageData.packModule || [], // Initialize as empty array if not defined
        isActive: packageData.isActive
    });
    console.log(isActive, "isActive")
    console.log(formData, "formDataformDataformDataformData")
    const [modulePackFormData, setModulePackFormData] = useState({
        name: '',
        nameRelatedToModule: '',
        moduleId: ''
    });
    console.log(modulePackFormData, "modulePackFormData")
    // ------------- END useStates -----------------------------------------


    //--------------useEffects -----------------------------------------------------
    useEffect(() => {
        dispatch(fetchAllPackagesModule());  // Hypothetical action to fetch modules
    }, [dispatch]);

    useEffect(() => {
        // Update state with new fields when packageData changes
        setFormData({
            ...packageData,
            dateDebut: packageData.dateDebut || '',
            dateExp: packageData.dateExp || '',
            packNbrSite: packageData.packNbrSite || '',
            packRqtNumber: packageData.packRqtNumber || '',
            packModule: packageData.packModule || [],
            isActive: isActive
        });
    }, [packageData, isActive]);

    //--------------END useEffects -----------------------------------------------------

    //--------------------------- FUNCTIONS --------------------------------------
    const formatDateForDisplay = (isoDate) => {
        if (!isoDate) return '';
        const [year, month, day] = isoDate.split('-');
        return `${month}/${day}/${year}`; // Convert back to mm/dd/yyyy
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "dateDebut" || name === "dateExp") {
            // Convert from yyyy-mm-dd (input format) to mm/dd/yyyy (internal format)
            setFormData(prev => ({
                ...prev,
                [name]: formatDateForDisplay(value)  // Assuming you have a function to convert back to mm/dd/yyyy
            }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSave = () => {
        // Check if formData is defined and contains necessary properties
        if (!formData || !formData.userId || !formData.packModule) {
            console.error("Missing essential data in formData:", formData);
            return; // Don't proceed if essential data is missing
        }
        const { userId } = formData;  // Make sure 'id' is always present in formData
        if (!userId) {
            console.error("Missing id or userId in formData");
            return;
        }
        console.log("Dispatching update with formData: ", formData);
        dispatch(updatePaymentById({ id, userId, formData, userRole: "Admin" }));
        onClose();
    };
    const handleModulePacksFormChange = (e) => {
        const { name, value } = e.target;

        // Ensure packModule is always an array
        setFormData((prevState) => {
            const updatedModules = value?.map((moduleId) => {
                const module = packagesModules.find((pm) => pm.moduleId === moduleId);
                return {
                    name: module?.name,
                    moduleId: module?.moduleId,
                    nameRelatedToModule: module?.name,
                };
            });

            return {
                ...prevState,
                packModule: updatedModules || [], // Ensure it's always an array
            };
        });
    };


    //--------------------------- END FUNCTIONS --------------------------------------

    const selectedModules = packagesModules?.map((pm) => pm.nameRelatedToModule) || [];
    console.log(selectedModules, "selectedModules")
    const moduleData = moduleLists.filter(item => item.isModule === true);
    console.log(moduleData, "moduleData")
    const availableModules = packagesModules.filter(
        (packageModule) => !formData.packModule?.some(
            (selectedModule) => selectedModule.moduleId === packageModule._id
        )
    );
    const formatDateForInput = (date) => {
        if (!date) return '';
        const parts = date.split('/');
        const [month, day, year] = parts?.map(part => part.padStart(2, '0'));  // Ensures parts are two digits
        return `${year}-${month}-${day}`; // Convert to yyyy-mm-dd
    };

    console.log(availableModules, "selectedModule")
    console.log(formatDate(formData.dateDebut), "formData")
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Update Package</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    name="dateDebut"
                    label="Start Date"
                    type="date"
                    fullWidth
                    variant="outlined"
                    value={formatDateForInput(formatDate(formData.dateDebut))}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    name="dateExp"
                    label="Expiration Date"
                    type="date"
                    fullWidth
                    variant="outlined"
                    value={formatDateForInput(formatDate(formData.dateExp))}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    margin="dense"
                    name="packNbrSite"
                    label="Number of Sites"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.packNbrSite || ''}
                    onChange={handleChange}
                    inputProps={{
                        min: 1  // Assuming you want at least 1 site
                    }}
                />
                <TextField
                    margin="dense"
                    name="packRqtNumber"
                    label="Pack Request Number"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formData.packRqtNumber || ''}
                    onChange={handleChange}
                    inputProps={{
                        min: -1
                    }}
                />
                {/* Continue to add more fields as needed */}
                <FormControl fullWidth>
                    <InputLabel id="packModule-label">Select Module</InputLabel>
                    <Select
                        name="moduleId"
                        value={formData.packModule?.map((pm) => pm.moduleId || [])}
                        onChange={(e) => {
                            const { value } = e.target; // Destructure value from the event
                            const updatedModules = value?.map((moduleId) => {
                                const module = packagesModules.find((pm) => pm.moduleId === moduleId);
                                return {
                                    name: module?.name,
                                    moduleId: module?.moduleId,
                                    nameRelatedToModule: module?.name,
                                };
                            });

                            // Update formData.packModule state
                            setFormData((prevData) => ({
                                ...prevData,
                                packModule: updatedModules,
                            }));

                            // Dispatch the update
                            // dispatch(updateInscrireAbon({
                            //     id,
                            //     userId: formData.userId,
                            //     formData: { ...formData, packModule: updatedModules },
                            //     userRole: "Admin"
                            // }));
                        }}
                        renderValue={(selected) =>
                            selected?.map((id) => packagesModules.find((pm) => pm.moduleId === id)?.name)
                                .filter((name) => name) // Avoid undefined names
                                .join(', ') // Join names with comma and space
                        } // Display selected module names
                        multiple // Enable multi-select
                        label="Select Module"
                    >
                        {packagesModules?.map((packageModule) => {
                            const isSelected = formData.packModule?.some(
                                (pm) => pm.moduleId === packageModule.moduleId // Check if matched
                            );
                            return (
                                <MenuItem key={packageModule.moduleId} value={packageModule.moduleId}>
                                    <Checkbox checked={isSelected} />
                                    <ListItemText
                                        primary={packageModule.name}
                                    />
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {isActive ?
                        <Button onClick={() => setIsActive(false)}>desactiver</Button>
                        :
                        <Button onClick={() => setIsActive(true)}>Activer</Button>
                    }

                </FormControl>





            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateTableInscriptionsAdmin;
