/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState, useRef } from 'react'
/* eslint-disable import/no-extraneous-dependencies */
import html2canvas from 'html2canvas';
/* eslint-enable import/no-extraneous-dependencies */

import JsPDF from 'jspdf';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Typography,
    Button,
    Alert,
    TextField,
    styled,
    tableCellClasses,
    IconButton,
    Select,
    MenuItem
} from "@mui/material";
import { Visibility, Edit, Height } from '@mui/icons-material';
import MailIcon from '@mui/icons-material/Mail';
import GetAppIcon from '@mui/icons-material/GetApp';

import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';

import UpdataTableIscriptionsAdmin from './UpdataTableIscriptionsAdmin'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import {  fetchLogEntryAggregates, getLogEntryAggregateClient, getTotalTraffic } from '../../../store/apps/logEntry/logEntrySlice';
import { getAllPaymentsAdmin, sendFactureEmail, updatePaymentById } from '../../../store/apps/paimentMethod/paymentSlice';

const InscriptionTableClient = ({ inscrireAbons, isLoading, error, onOpenDetails, logReport, query }) => {


    const { paymentList } = useSelector((state) => state.payment);
    console.log(paymentList, "paymentListsss")
    const { site } = useSelector((state) => state.userReducer);
    const siteId = site?._id;
    console.log(siteId, "siteId")
    const { trafficData, logAggregates, totalTraffic } = useSelector((state) => state.logEntryReducer);
    console.log(totalTraffic, "totalTraffic")
    const { users, isAuthAuth } = useSelector((state) => state.userReducer);
    const userId = users?.id;
    const userRole = users?.userRole;

    console.log(userId, "userId")
    const dispatch = useDispatch();
    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        let month = `${d.getMonth() + 1}`;
        let day = `${d.getDate()}`;
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return `${year}-${month}-${day}`;
    };
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handleOpenUpdateModal = (packageData) => {
        setSelectedPackage(packageData);
    };

    const handleCloseModal = () => {
        setSelectedPackage(null);
    };

    const handleSaveUpdatedPackage = (updatedData) => {
        // Make sure to include the id when dispatching the update
        if (selectedPackage && selectedPackage._id) {
            dispatch(updatePaymentById({
                id: selectedPackage._id, // Assuming your data has an '_id' field from MongoDB
                paymentList: updatedData
            }));
        }
        handleCloseModal();
    };



    // -------------------------- TRADUCTIONS ----------------------
    const { t, i18n } = useTranslation()

    const currentLanguage = i18n.language;
    const textsToTranslate = {
        pack_name: 'Pack Name',
        start_date: "Start Date:",
        expire_date: "Expiry Date:",
        status: "status",
        transiction_status: "Transaction status",
        rqt_number: "nombre de requetes",
        nbr_site: "Number of sites",
        actions: "Actions",
        voir_det: "View Details",
        update: "Update",
        desactiver: "Desactiver",
        completed: "Completed",
        unlimited: "Unlimited",
        active: "Active"
    };
    const translatedTexts = useTranslateTexts(textsToTranslate);
    const generateTranslationKeys = useMemo(() => {
        const keys = {};
        inscrireAbons?.forEach(abon => {
            keys[`packName_${abon._id}`] = abon.packName;  // This is correct.
            keys[`statusName_${abon._id}`] =t(abon.status);
            console.log(keys[`statusName_${abon._id}`], "keystatus")
        });
        return keys;
    }, [inscrireAbons]);


    const calculateStatusKey = (dateDebut, dateExp) => {
        const now = new Date();
        const startDate = new Date(dateDebut);
        const expiryDate = new Date(dateExp);
        const threeDaysBefore = new Date(expiryDate);
        threeDaysBefore.setDate(threeDaysBefore.getDate() - 3);

        if (now >= startDate && now <= expiryDate) {
            if (now >= threeDaysBefore && now < expiryDate) {
                return 'Expiring soon'; // Consistent key for translation
            }
            return `${translatedTexts?.active}`;
        }
        return 'Expired';
    };

    const statusKeys = useMemo(() => {
        return inscrireAbons.reduce((acc, abon) => {
            const key = calculateStatusKey(abon.dateDebut, abon.dateExp); // Function to determine status key
            acc[`status_${abon._id}`] = t(key); // Use the translation function directly if available
            return acc;
        }, {});
    }, [inscrireAbons, i18n.language]); // Add i18n.language to dependencies to update on language change

    // const statusNameKeys = useMemo(() => {
    //     return inscrireAbons.reduce((acc, abon) => {
    //         // Ensure the key name aligns with how you've structured your i18next translation keys.
    //         acc[`status_${abon._id}`] = `status.${abon.status.toLowerCase()}`; // Example key: status.active
    //         return acc;
    //     }, {});
    // }, [inscrireAbons]);


    const translatedStatuses = useTranslateTexts(statusKeys);

    const translatedPackNames = useTranslateTexts(generateTranslationKeys);
    console.log(translatedPackNames, "translatedPackNames")
    // -------------------------- END TRADUCTIONS ----------------------
    // -----------------------------fILTER ---------------------------------
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        status: ''
    });

    const handleFilterChange = (field, value) => {
        setFilters(prev => ({ ...prev, [field]: value }));
    };
    const calculateStatus = (dateDebut, dateExp) => {
        const now = new Date();
        const startDate = new Date(dateDebut);
        const expiryDate = new Date(dateExp);
        const threeDaysBefore = new Date(expiryDate);
        threeDaysBefore.setDate(threeDaysBefore.getDate() - 7);

        if (now >= startDate && now <= expiryDate) {
            if (now >= threeDaysBefore && now < expiryDate) {
                return 'Expiring soon'; // Just return the status as string
            }
            return 'Active'; // Return 'Active' if within the dates and not close to expiring
        }
        return 'Expired'; // Return 'Expired' if past the expiry date
    };
    const filteredInscriptions = useMemo(() => inscrireAbons?.filter(insc => {
        const filterStartDate = filters.startDate ? new Date(filters.startDate + 'T00:00:00') : null;
        const filterEndDate = filters.endDate ? new Date(filters.endDate + 'T23:59:59') : null;
        const status = calculateStatus(insc.dateDebut, insc.dateExp).toLowerCase();  // Lowercase for case-insensitive comparison

        return (
            (!filters.username || insc.userName.toLowerCase().includes(filters.username.toLowerCase())) &&
            (!filterStartDate || new Date(insc.dateDebut) >= filterStartDate) &&
            (!filterEndDate || new Date(insc.dateExp) <= filterEndDate) &&
            (!filters.status || status.includes(filters.status.toLowerCase()))  // Check if status includes the filter status
        );
    }), [inscrireAbons, filters]);


    // console.log(
    //     filteredInscriptions?.site.map((site) => site?.site_name ) ,
    //     "filteredInscriptions"
    // );
    // -----------------------------END fILTER ---------------------------------

    // ------------------------------ requete number------------------------------



    useEffect(() => {
        if (siteId) {
            dispatch(getLogEntryAggregateClient(siteId)); // Correct dispatching
            dispatch(fetchLogEntryAggregates(siteId)); // Correct dispatching
            dispatch(getTotalTraffic(siteId)); // Correct dispatching
        }
    }, [dispatch, siteId]);
    // console.log(typeof (trafficData.totalTrafficData), "trafficData")
    // console.log(typeof (logAggregates.totalTrafficData), "logAggregates")

    const totale_requete = trafficData?.totalTrafficData + logAggregates?.totalTrafficData;
    console.log(totalTraffic, "totale_requete")


    // ------------------------------------



    // useEffect(() => {
    //     const fetchPayments = async () => {
    //         await dispatch(getAllPaymentsAdmin()); // Dispatch action to fetch all payments
    //     };

    //     fetchPayments(); // Fetch once on mount

    //     const interval = setInterval(() => {
    //         fetchPayments(); // Fetch periodically every 10 seconds
    //     }, 10000); // Adjust interval as needed

    //     return () => clearInterval(interval); // Cleanup on component unmount
    // }, [dispatch]);


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#60b3a6',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    // Styled TableRow
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    // const siteNameArrays = inscrireAbons.map((abon) => {
    //   // Make sure we're always dealing with an array
    //   const siteArray = Array.isArray(abon.site)
    //     ? abon.site
    //     : [abon.site].filter(Boolean);

    //   return siteArray.map((s) => s?.site_name);
    // });

    // console.log(siteNameArrays, "sssss");


    // ---------------------------------- Download pdf ----------------------

    const generateStyledPDF = (abon) => {
        const hiddenDiv = document.createElement('div');
        hiddenDiv.style.position = 'absolute';
        hiddenDiv.style.left = '-9999px'; // Off-screen
        hiddenDiv.style.top = '0';
        hiddenDiv.style.width = '600px';
        hiddenDiv.style.height = '840px'; // Ensure div is large enough
        hiddenDiv.style.background = '#fff';
        hiddenDiv.style.padding = '20px';
        hiddenDiv.style.fontFamily = 'Arial, sans-serif';
        const timberFiscal = 1000;
        const timberFiscalFormatted = timberFiscal.toLocaleString('de-DE'); // This formats the number using French locale

        hiddenDiv.innerHTML = `
   <div
    style="width: 560px; height: 830px; display: flex; align-items: center; justify-content: center;position: relative;">
    <div
        style="width: 90%; height: 95%; border: 1px solid black; display: flex;flex-direction: column;column-gap:20px;align-items:center ; justify-content: flex-start;">
        <!-- header div  -->
        <!-- Header Section -->
<div style="display: flex; align-items: center; justify-content: space-around; width: 95%; height: 15%; padding: 10px; background: #fff;">
<!-- Logo on the left -->
<div style="flex: 0 0 auto; width: 75px; height: 50px;">
<img src="https://cdn.edi-static.fr/image/upload/c_scale,f_auto,h_564,q_auto/v1/Img/FICHEPRATIQUE/2021/10/365359/Comment-generer-facilement-code--LE.jpg" alt="SECURAS QR Code" style="width: 100%; height: 100%; object-fit: contain;">
</div>

<!-- Company details in the second column -->
<div style=" display: flex; flex-direction: column; align-items: center; justify-content: center;">
<p style="font-size: 8px; color: #60b3a6; font-weight: bold; margin: 0;">SECURAS TECHNOLOGIES</p>
<p style="font-size: 8px; color: #60b3a6; margin: 0;">SARL au capital de 1950 TND</p>
<p style="font-size: 8px; color: #60b3a6; margin: 0;">Adresse: Route Gremda Km 1, Sfax, Tunisie</p>
<p style="font-size: 8px; color: #60b3a6; margin: 0;">MF: 1673702y</p>
</div>

<!-- Contact details in the third column -->
<div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: center;">
<!-- Phone Icon -->
<div style="font-size: 8px; color: #60b3a6; display: flex; align-items: center; gap: 5px;">
<div style="width: 13px; height: 13px; background-color: #55556e; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
    <svg style="fill: white; width: 8px; height: 8px;" viewBox="0 0 16 16">
        <!-- Standard phone icon -->
        <path d="M3.654 2.896l1.11-.79c.944-.675 2.253-.152 2.503.982l.315.931c.25 1.134-.407 2.282-1.351 2.958L5.482 8.319c.885 2.365 2.595 3.991 4.786 4.576l.936-1.133c.676-.817 1.823-1.474 2.958-1.224l.93.314c1.134.25 1.657 1.559.982 2.503l-.791 1.111c-.759 1.07-2.082 1.605-3.34 1.605-4.604 0-8.95-3.474-11.193-9.72C.057 7.074.592 5.75 1.663 4.99l1.11-.791c.675-.481 1.984-.008 2.234 1.126l.314.931c.251 1.134-.314 2.232-1.134 2.782l-.934.626a33.675 33.675 0 0 0 4.311 4.311l.627-.934c.549-.82 1.648-1.385 2.782-1.134l.93.315c1.134.25 1.608 1.559 1.127 2.234z"/>
    </svg>
</div>
<span>+216 94 844 900</span>
</div>

<!-- Email Icon -->
<div style="font-size: 8px; color: #60b3a6; display: flex; align-items: center; gap: 5px;">
<div style="width: 13px; height: 13px; background-color: #55556e; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
<svg style="fill: white; width: 8px; height: 8px;" viewBox="0 0 24 24">
    <path d="M21,4H3C1.897,4,1,4.897,1,6v12c0,1.103,0.897,2,2,2h18c1.103,0,2-0.897,2-2V6C23,4.897,22.103,4,21,4z M20.766,6l-8.699,5.455
        L3.234,6H20.766z M20,18H4v-9.561l8.065,5.055c0.189,0.118,0.404,0.178,0.619,0.178c0.215,0,0.431-0.06,0.619-0.178L20,8.439
        V18z"/>
</svg>
</div>
<span>contact@securas.fr</span>
</div>


<!-- Globe (Internet) Icon -->
<div style="font-size: 8px; color: #60b3a6; display: flex; align-items: center; gap: 5px;">
<div style="width: 13px; height: 13px; background-color: #55556e; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
    <svg style="fill: white; width: 8px; height: 8px;" viewBox="0 0 16 16">
        <path d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zM1 8c0-.7.12-1.37.33-2h13.35c.2.63.32 1.3.32 2s-.12 1.37-.33 2H1.33C1.12 9.37 1 8.7 1 8zm14.07-3H9V1.07C11.61 1.52 13.6 3.39 14.07 5zM7 1.07V5H1.93C2.4 3.39 4.39 1.52 7 1.07zM1.93 11H7v3.93C4.39 14.48 2.4 12.61 1.93 11zm5.07 3.93V11h6.07c-.47 1.61-2.46 3.48-5.07 3.93zm6.07-5H9V6h6.07c.21.63.33 1.3.33 2s-.12 1.37-.33 2z"/>
    </svg>
</div>
<span>www.securas.fr</span>
</div>
</div>




<!-- QR Code on the right -->
<div style="flex: 0 0 auto; width: 75px; height: 50px;">
<img src="https://cdn.edi-static.fr/image/upload/c_scale,f_auto,h_564,q_auto/v1/Img/FICHEPRATIQUE/2021/10/365359/Comment-generer-facilement-code--LE.jpg" alt="SECURAS QR Code" style="width: 100%; height: 100%; object-fit: contain;">
</div>
</div>


        <!-- header div  -->
        <!-- under header div  -->
               <div
                style="margin:10px 0 ;display: flex;align-items: flex-start;justify-content: center; width: 100%; height: 20%; ; font-size: 12px;font-family: Arial, Helvetica, sans-serif;">
                <div>
                    <p>A l'attention de :</p>
                    <div style="display: flex;align-items: flex-start;justify-content: center;gap:200px;">
                        <div>
                            <h4 style=" font-size: 9px;">Nom et Prénom :${abon.username} </h4>
                            <h4 style=" font-size: 9px;">email:${abon.email} </h4>
                            <h4 style=" font-size: 9px;">numéro de telephone :${abon.phoneNumber} </h4>
                        </div>
                        <div>
                            <h4 style=" font-size: 9px;">date de debut:${formatDate(abon.dateDebut)} </h4>
                            <h4 style=" font-size: 9px;">date d'expiration:${formatDate(abon.dateExp)} </h4>
                            <h4 style=" font-size: 9px;">${abon.packPeriod === "365 jours" ? 'Annuel' : 'Mensuel'}  </h4>
                        </div>

                    </div>


                </div>

            </div>
        <!-- under header div  -->
        <!-- table  div  -->
        <div style="width: 95%; height: max-content; ">
            <div >
<table
    style="width: 100%; border-collapse: collapse; text-align: center; font-family: Arial, sans-serif;">
    <thead>
        <tr style="background-color: #007596; font-size: 8px; color: #fff;">
            <th style="border: 1px solid black; padding: 10px;">Description de l'élement</th>
            <th style="border: 1px solid black; padding: 10px;">Prix</th>
            <th style="border: 1px solid black; padding: 10px;">Nombre de site</th>
            <th style="border: 1px solid black; padding: 10px;">Totale</th>
        </tr>
    </thead>
    <tbody>
        <tr style="font-size:10px">
            <td rowspan="2" style="border: 1px solid black; padding: 10px;font-size: 10px;text-align: left;">
              Package  :${abon.packName} </br>
            Site :${abon.site?.site_name} </br>
                Module inclue : ${abon.packModule.map((pack) => pack.name)}
            </td>
            <td rowspan="2" style="border: 1px solid black; padding: 10px;">
                  ${(abon.amount / 1.19).toLocaleString('de-DE')}${abon.token} 
            </td>
            <td rowspan="2" style="border: 1px solid black; padding: 10px;">1</td>
            <td rowspan="2" style="border: 1px solid black; padding: 10px;">
            
    ${(abon.amount / 1.19).toLocaleString('de-DE')}${abon.token} 
            </td>
        </tr>
        <tr>
        </tr>
        <tr style="font-size: 7px">
            <td rowspan="4" style="padding: 10px;"></td>
            <td rowspan="4" style=" padding: 10px;"></td>
            <td style="border: 1px solid black; padding: 10px;">Total HT</td>
            <td style="border: 1px solid black; padding: 10px;">
                  ${(abon.amount / 1.19).toLocaleString('de-DE')}${abon.token} 
            </td>
        </tr>
        <tr style="font-size: 7px">
            <td style="border: 1px solid black; padding: 10px;">TVA</td>
            <td style="border: 1px solid black; padding: 10px;">19%</td>
        </tr>
        <tr style="font-size: 7px">
            <td style="border: 1px solid black; padding: 10px;">Timber fiscal</td>
            <td style="border: 1px solid black; padding: 10px;">
          ${timberFiscalFormatted} TND
        </td>
        </tr>
        <tr style="font-size: 7px">
            <td style="border: 1px solid black; padding: 10px;">Net à payer</td>
            <td style="border: 1px solid black; padding: 10px;">
                ${(abon.amount).toLocaleString('de-DE')}${abon.token}
            </td>
        </tr>
    </tbody>
</table>


            </div>
        </div>
        <!-- table div  -->
        <!-- Detail de client div  -->
        <div
            style="width: 90%;font-size: 9px; height: max-content;font-family: Arial, Helvetica, sans-serif ;font-weight: 600;display: flex;align-items: flex-start; justify-content: space-between;">
                    <div>
                                        <h2 style="font-size: 11px;font-weight: 600">Details</h3>

                                <p style="font-size: 8px;font-weight: 600";color:"black">Date de paiment:${formatDate(abon.createdAt)}</p>
                                 <p style="font-size: 8px;font-weight: 600";color:"black">Facture reference :${abon.paymentRef}</p>
                                 <p style="font-size: 8px;font-weight: 600";color:"black">Sender Wallet:${abon.transactions[1]?.senderWallet?.owner.name}</p>
                                 <p style="font-size: 8px;font-weight: 600";color:"black">Bank Name:${abon.transactions[0]?.extSenderInfo?.bankInfo?.bankName}</p>
                                 <p style="font-size: 8px;font-weight: 600";color:"black"> Bank Country:${abon.transactions[0]?.extSenderInfo?.bankInfo?.bankCountryName}</p>
                      <h4 style=" font-size: 9px; color:green">Status de paiement :${abon.status} </h4>

               
            </div>
            <h4 font-size: 10px;>Cachet et signature</h4>
        </div>
        <!-- Detail de clientdiv  -->
        <!-- signture  div  -->
        <div style="width: 90%;font-size: 9px; height: 15% ;font-family: Arial, sans-serif;font-weight: 800;display: flex;align-items: end; justify-content: space-between;">
            
        </div>
        <!-- signture div  -->
        <!-- footer  div  -->
        <div style="width: 90%;
        height: 15%;
        display: flex
    ;
        align-items: flex-start; ">
        
        </div>
        <div style="width: 95%;display: flex;align-items: center;">
            <p
                style="color: #43435f;height: 30px; font-size: 97px;font-family: Arial, Helvetica, sans-serif;position: absolute;top: 680px">
                SECURAS</p>
        </div>
        <!-- footer div  -->
    </div>
</div>
        `;

        document.body.appendChild(hiddenDiv);

        setTimeout(() => { // Delay to ensure all contents are fully loaded
            html2canvas(hiddenDiv, {
                scale: window.devicePixelRatio * 2, // Enhance quality by using device pixel ratio
                useCORS: true
            }).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new JsPDF('p', 'mm', 'a4');
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();
                const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth * ratio, imgHeight * ratio);
                const timestamp = Date.now();

                pdf.save(`Facture-${abon.username}-${timestamp}.pdf`);
            }).catch(err => {
                console.error('Error capturing the canvas:', err);
            }).finally(() => {
                document.body.removeChild(hiddenDiv); // Clean up after saving
            });
        }, 1000); // Adjust timing as needed
    };
    // --------------------------Transactions ------------------------

    // const statusForTranslation = inscrireAbons.reduce((acc, abon) => {
    //     acc[`statusName_${abon._id}`] = abon?.status;
    //     return acc;
    // }, {});
    const translatedStatusNames = useTranslateTexts(generateTranslationKeys);

    // const translatedStatusNames = useTranslateTexts(generateTranslationKeys);
    console.log(translatedStatusNames, "translatedStatusNames");
    return (
        <>
            <TextField
                label="Start Date"
                type="date"
                value={filters.startDate}
                onChange={(e) => handleFilterChange('startDate', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="End Date"
                type="date"
                value={filters.endDate}
                onChange={(e) => handleFilterChange('endDate', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: filters.startDate ? filters.startDate : undefined }}
                disabled={!filters.startDate}
            />
            <Select
                label="Status"
                value={filters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
                displayEmpty
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8, width: 200 }}
            >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="expired">Expired</MenuItem>
                <MenuItem value="expiring soon">Expiring Soon</MenuItem>
            </Select>




            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow >
                            <StyledTableCell align="left">{translatedTexts?.pack_name}</StyledTableCell>
                            <StyledTableCell align="left">{translatedTexts?.status}</StyledTableCell>
                            <StyledTableCell align="left">{translatedTexts?.transiction_status}</StyledTableCell>
                            <StyledTableCell align="left">{translatedTexts?.nbr_site}</StyledTableCell>
                            <StyledTableCell align="left">{translatedTexts?.rqt_number}</StyledTableCell>
                            <StyledTableCell align="left">{translatedTexts?.start_date}</StyledTableCell>
                            <StyledTableCell align="left">{translatedTexts?.expire_date}</StyledTableCell>
                            <StyledTableCell align="left">{translatedTexts?.actions}</StyledTableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {filteredInscriptions?.map((abon) => {
                            const isExpiringSoon = ['Expiring soon', 'تنتهي صلاحيتها قريباً', 'Expirant bientôt'].includes(translatedStatuses[`status_${abon._id}`]);
                            return (
                                <TableRow key={abon.packId}>
                                    <StyledTableCell>{translatedPackNames[`packName_${abon._id}`] || abon.packName}</StyledTableCell>
                                    <StyledTableCell>
                                        {!abon.isActive ? (
                                            <>{translatedTexts?.desactiver}</>  // Assuming this is for "Deactivated" or similar
                                        ) : abon.status === 'completed' ? (
                                            isExpiringSoon ? (
                                                <Alert severity="warning" icon={<WarningIcon />} style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                                                    {translatedStatuses[`status_${abon._id}`]}
                                                </Alert>
                                            ) : (
                                                <>{translatedStatuses[`status_${abon._id}`]}</>
                                            )
                                        ) : (
                                            <>N/A</>  // Display when no other conditions are met
                                        )}
                                    </StyledTableCell>

                                    <StyledTableCell>

                                        {translatedStatusNames[`statusName_${abon._id}`] || abon.status}

                                    </StyledTableCell>
                                    <StyledTableCell>{abon.packNbrSite}</StyledTableCell>
                                    <StyledTableCell>{abon.packRqtNumber === -1 ? <>{translatedTexts?.unlimited}</> : <>{totale_requete}/{abon.packRqtNumber}</>}</StyledTableCell>
                                    <TableCell>{formatDate(abon.dateDebut)}</TableCell>
                                    <TableCell>{formatDate(abon.dateExp)}</TableCell>

                                    <StyledTableCell sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => onOpenDetails(abon)}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                padding: 0, // Removes default padding to make the button size exactly 40x40
                                            }}
                                        >
                                            <IconButton color="primary" style={{ width: "100%", height: "100%" }}>
                                                <Visibility />
                                            </IconButton>
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => dispatch(sendFactureEmail({
                                                email: abon.email,
                                                username: abon.username,
                                                phoneNumber: abon.phoneNumber,
                                                packName: abon.packName,
                                                paymentRef: abon.paymentRef,
                                                packModule: abon.packModule.map((pack) => pack.name),
                                                amount: abon.amount,
                                                token: abon.token,
                                                siteName: abon.site?.site_name,
                                                userId: userId,
                                                dateExp: formatDate(abon.dateExp)
                                            }))}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                padding: 0, // Removes default padding to make the button size exactly 40x40
                                            }}
                                        >
                                            <IconButton color="primary" style={{ width: "100%", height: "100%" }}>
                                                <MailIcon />
                                            </IconButton>
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => generateStyledPDF(abon)}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                padding: 0, // Removes default padding to make the button size exactly 40x40
                                            }}
                                        >
                                            <IconButton color="primary" style={{ width: "100%", height: "100%" }}>
                                                <GetAppIcon />
                                            </IconButton>
                                        </Button>


                                        {userRole === "Admin" && (
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => handleOpenUpdateModal(abon)}
                                            >
                                                {translatedTexts?.update}
                                            </Button>
                                        )}
                                    </StyledTableCell>


                                </TableRow>
                            );
                        })}
                    </TableBody>

                </Table>
                {selectedPackage && (
                    <UpdataTableIscriptionsAdmin
                        open={!!selectedPackage}
                        packageData={selectedPackage}
                        id={selectedPackage?._id}
                        onClose={handleCloseModal}
                        onSave={handleSaveUpdatedPackage}
                        formatDate={formatDate}
                    />
                )}

            </TableContainer>

        </>
    )
}

export default InscriptionTableClient
InscriptionTableClient.propTypes = {
    query: PropTypes.object,
    logReport: PropTypes.object,

};