import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentById, getPaymentsByUserId, saveTransaction } from "../../../store/apps/paimentMethod/paymentSlice";
import { Box, Button } from "@mui/material";
// import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';

const PaymentSuccess = () => {
    const [searchParams] = useSearchParams();
    const paymentRef = searchParams.get("payment_ref");
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.userReducer);
    const userId = users.id;
    const navigate = useNavigate();
    // Fetch payment data from the Redux store
    const { paymentData, paymentStatus } = useSelector((state) => state.payment);
    const isSaved = useSelector(state => state.payment.transactionSaved);
    console.log(isSaved, "isSaved")
    console.log(paymentData, "paymentData")
    const { paymentListUser } = useSelector(state => state.payment);

    useEffect(() => {
        if (paymentRef) {
            dispatch(getPaymentById({paymentRef,userId}));
            dispatch(getPaymentsByUserId(userId));
        }
    }, [paymentRef, dispatch, userId]);

    useEffect(() => {
        if (paymentData.status === 'completed' && paymentRef) {
            dispatch(saveTransaction({
                paymentRef,
                transactionData: paymentData.transactions
            }))
                .then(action => {
                    if (action.meta.requestStatus !== 'fulfilled') {
                        console.error("Failed to save transaction.");
                    }
                });
        }
    }, [paymentData.status, paymentRef, dispatch]);

    // const renderTransactionDetails = (transactions) => {
    //     // Only render the first transaction, leave the others empty
    //     const firstTransaction = transactions[0]; // Get the first transaction



    //     return (
    //         <div>
    //             <h4>Transaction </h4>
    //             <p><strong>Bank Name:</strong> {firstTransaction?.extSenderInfo?.bankInfo?.bankName || 'N/A'}</p>
    //             <p><strong>Bank Country Code:</strong> {firstTransaction?.extSenderInfo?.bankInfo?.bankCountryCode || 'N/A'}</p>
    //             <p><strong>Bank Country Name:</strong> {firstTransaction?.extSenderInfo?.bankInfo?.bankCountryName || 'N/A'}</p>
    //             <p><strong>Payment System:</strong> {firstTransaction?.extSenderInfo?.paymentSystem || 'N/A'}</p>
    //             <p><strong>Card Holder Name:</strong> {firstTransaction?.extSenderInfo?.name || 'N/A'}</p>
    //             <p><strong>Email:</strong> {firstTransaction?.extSenderInfo?.email || 'N/A'}</p>
    //             <p><strong>Type:</strong> {firstTransaction?.type || 'N/A'}</p>
    //             <p><strong>Method:</strong> {firstTransaction?.method || 'N/A'}</p>
    //             <p><strong>Status:</strong> {firstTransaction?.status || 'N/A'}</p>
    //             <p><strong>Amount:</strong> {firstTransaction?.amount || 'N/A'} {firstTransaction?.token || 'N/A'}</p>
    //             <p><strong>Sender Wallet:</strong> {firstTransaction?.senderWallet?.owner?.name || 'N/A'}</p>
    //         </div>
    //     );
    // };
    const handleRedirect = () => {
        navigate('/transactionClient'); // Replace '/another-page' with the path you want to redirect to
    };
    return (
        <div>
            <Box
                style={{

                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh"
                }}
            >

                {/* <CheckCircleSharpIcon style={{ color: 'green', fontSize: "250px" }} /> */}
                <Box>

                    {paymentStatus === 'succeeded' ? (
                        paymentListUser
                            .filter((el) => el?.paymentRef === paymentRef) // Filter based on matching paymentRef
                            .map((el) => (
                                <Box key={el.paymentRef} style={{ color: "black" }}>
                                    <Box style={{
                                        fontSize: "40px",
                                        fontWeight: "bold",
                                        fontStyle: "italic",
                                        color: "black",
                                        textAlign: "center",
                                        marginTop: "20px"
                                    }}>
                                        Congratulations {el?.username}!

                                    </Box>
                                    <Box style={{ textAlign: "center" }}>
                                        <p>
                                            <p>
                                                Your payment of <strong>{(paymentData?.amount).toLocaleString('de-DE')} {paymentData?.token}</strong> has been successfully processed for the <strong>{el?.packName}</strong> package.
                                            </p>
                                        </p>

                                    </Box>

                                    <Box style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                     
                                    }}>
                                        <Button
                                            sx={{
                                                background: 'linear-gradient(45deg, rgb(64, 199, 181), rgb(62, 137, 218), rgb(64, 199, 181))', // Gradient background
                                                color: 'transparent', // Transparent text color to show gradient through text
                                                WebkitBackgroundClip: 'text', // Clips the background gradient to the text
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                padding: '10px 20px',
                                                textTransform: 'none',
                                                border: '2px solid transparent' // Optional border to make the button visible
                                            }}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleRedirect}
                                        >
                                            More details
                                        </Button>
                                    </Box>

                                    {/* <p><strong>Payment Reference:</strong> {paymentRef}</p> */}

                                    {/* Display all payment details */}
                                    {/* <p><strong> CyberSheildFull Name:</strong> </p>
                                    <p><strong>CyberSheild Email:</strong> {paymentData?.paymentDetails?.email}</p>
                                    <p><strong>CyberSheild Phone:</strong> {paymentData?.paymentDetails?.phoneNumber}</p>
                                    <p><strong>Pack Name:</strong> {el?.packName}</p>
                                    <p><strong>Payment Status:</strong> {el?.status}</p>
                                    <p><strong>Amount Paid:</strong> {paymentData?.amount} {paymentData?.token}</p>
                                    <p><strong>Payment Date:</strong> {paymentData?.createdAt}</p>
                                    <p><strong>Site Name:</strong> {el?.site?.site_name}</p> */}

                                    {/* Render the first transaction details */}
                                    {/* {el?.transactions && renderTransactionDetails(el.transactions)} */}

                                    {/* <Button variant="contained" color="primary">
                                Download Payment Details as PDF
                            </Button> */}
                                </Box>
                            ))
                    ) : (
                        <p>Loading payment details...</p>
                    )}
                </Box>


            </Box>


        </div>
    );
};

export default PaymentSuccess;
