import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";

//add PACK
export const createPack = createAsyncThunk("pack/add", async ({ pack, userRole }) => {
    try {
        let url = `/package/addPackage?userRole=${userRole}`;
        const result = await Axios.post(url, pack);

        return result.data;
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error to handle it in the calling component
    }
});
//get all PACK
// Async thunk to fetch all packages
export const fetchAllPackages = createAsyncThunk(
    "packages/fetchAll",
    async ({ userId }, { rejectWithValue }) => {
        try {
            let url = `/package/getAllpackages`;
            let res = await Axios.get(url);
            console.log("res", res);
            return res.data;
        } catch (error) {
            console.error("Error fetching packages:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);


//del pack
export const deletePack = createAsyncThunk("packs/delete", async ({ id, userId, userRole }, { rejectWithValue }) => {
    try {
        // Construct the URL with query parameters
        let url = `/package/deletePackagebyId?id=${id}&userRole=${userRole}`;
        let res = await Axios.delete(url);
        return res.data;
    } catch (error) {
        console.error("Error deleting package:", error);
        return rejectWithValue(error.response?.data || "Something went wrong");
    }
});



export const updatePack = createAsyncThunk(
    "packs/update",
    async ({ id, userId, updatedData, userRole }, { rejectWithValue }) => {
        try {
            // Construct the URL with query parameters
            let url = `/package/updatePackageById?id=${id}&userRole=${userRole}`;
            let res = await Axios.put(url, updatedData);
            return res.data;
        } catch (error) {
            console.error("Error updating package:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

export const fetchPackageById = createAsyncThunk(
    "packages/fetchById",
    async ({id}, { getState, rejectWithValue }) => {
  
        try {
            const response = await Axios.get(`/package/getPackagebyid?id=${id}`); // Ensure this matches your backend endpoint
            if (response.status !== 200) {
                throw new Error('Failed to fetch package');
            }
            return response.data;
        } catch (error) {
            console.error("Error fetching package by ID:", error);
            return rejectWithValue(error.response?.data || "Failed to fetch package");
        }
    }
);


const initialState = {
    packages: [], // Initialized as an empty array
    package: null,
    status: null,
    error: null,
    isFetchingPackages: false,
};
export const packSlice = createSlice({
    name: "packs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //add packs extra reducers
        builder
            .addCase(createPack.pending, (state) => {
                state.status = "loading...";
            })
            .addCase(createPack.fulfilled, (state, action) => {
                state.status = "success";
                state.packs = action.payload?.packs;

                return state;
            })
            .addCase(createPack.rejected, (state) => {
                state.status = "fail";
            })

            // ----------------------- get Pack------------------
            .addCase(fetchAllPackages.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllPackages.fulfilled, (state, { payload }) => {
                // console.log("Fetched packages:", action.payload);
                state.status = "succeeded";
                state.packages = payload;
                state.isFetchingPackages = true;

            })
            .addCase(fetchAllPackages.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload; // Store the error message
            })

            // ----------------------- get Pack------------------
            // builder
            //   .addCase(getPack.pending, (state) => {
            //     state.status = "loading";
            //   })
            //   .addCase(getPack.fulfilled, (state, action) => {
            //     state.status = "fulfilled";
            //     state.packs = action.payload?.pack;
            //   })
            //   .addCase(getPack.rejected, (state) => {
            //     state.status = "failed";
            //   });

            // -----------------------DELETE pack ----------------------

            .addCase(deletePack.pending, (state) => {
                state.status = "pending";
            })
            .addCase(deletePack.fulfilled, (state, action) => {
                state.status = "fulfilled";
                const deletedId = action.meta.arg.id;
                state.packages = state.packages.filter((pack) => pack._id !== deletedId);
            })
            .addCase(deletePack.rejected, (state) => {
                state.status = "rejected";
            })
            // ----------------------- Update Pack ------------------
            .addCase(updatePack.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updatePack.fulfilled, (state, action) => {
                const updatedPackage = action.payload; // Updated package data from API response
                const index = state.packages.findIndex(pack => pack._id === updatedPackage._id);
                if (index !== -1) {
                    state.packages[index] = updatedPackage; // Update package in state
                }
            })
            .addCase(updatePack.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload; // Store the error message
            })
            // get package by id 
            .addCase(fetchPackageById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchPackageById.fulfilled, (state, action) => {
                state.PackagetStatus = 'succeeded';
                state.PackageData = action.payload.data?.package;
            })
            .addCase(fetchPackageById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload; // Store the error message
            })
    },


});

export default packSlice.reducer;