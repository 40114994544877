/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Typography,
    Button,
    Alert,
    TextField,
    tableCellClasses,
    styled,
    IconButton,
    Select,
    MenuItem,
} from "@mui/material";
import { Visibility, Edit, Height } from '@mui/icons-material';

import UpdataTableIscriptionsAdmin from './UpdataTableIscriptionsAdmin'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllPaymentsAdmin, updatePaymentById } from '../../../store/apps/paimentMethod/paymentSlice';

const PackageTableAdmin = ({ packages, isLoading, error, onOpenDetails, query, logReport }) => {
    const dispatch = useDispatch();
    console.log(packages,"packagesss")
    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        let month = `${d.getMonth() + 1}`;
        let day = `${d.getDate()}`;
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return `${month}/${day}/${year}`;
    };
    const [filters, setFilters] = useState({
        username: '',
        startDate: '',
        endDate: '',
        status: ''
    });
    const [selectedPackage, setSelectedPackage] = useState(null);
    const handleOpenUpdateModal = (packageData) => {
        console.log("Opening modal for package:", packageData);  // Debug log to verify the function works
        setSelectedPackage(packageData);  // Ensure state is set correctly
    };

    const handleCloseModal = () => {
        console.log("Closing modal");  // Debug log to verify the function works
        setSelectedPackage(null);  // Reset state to null, closing the modal
    };

    const handleSaveUpdatedPackage = (updatedData) => {
        if (!selectedPackage) {
            console.error("Selected package is not defined");
            return;
        }

        if (!updatedData) {
            console.error("Updated data is empty");
            return;
        }

        if (!updatedData.packModule || updatedData.packModule.length === 0) {
            console.error("Missing modules in updated data");
            return;
        }

        // If all validations pass, dispatch the update
        dispatch(updatePaymentById({
            id: selectedPackage._id, // Assuming your data has an '_id' field from MongoDB
            inscrireAbon: updatedData
        }));

        handleCloseModal();
    };

    const calculateStatus = (dateDebut, dateExp) => {
        const now = new Date();
        const startDate = new Date(dateDebut);
        const expiryDate = new Date(dateExp);
        const threeDaysBefore = new Date(expiryDate);
        threeDaysBefore.setDate(threeDaysBefore.getDate() - 7);

        if (now >= startDate && now <= expiryDate) {
            if (now >= threeDaysBefore && now < expiryDate) {
                // Return alert component if within three days before expiration
                return <Alert severity="warning" sx={{ height: "40px", fontSize: "14px", overflow: "hidden", display: "flex", alignItems: "center" }}>
                    Expiring soon!
                </Alert>

            }
            return 'Active'; // Active if current date is within start and expiry date, but not close to expiry
        }
        return 'Expired'; // Expired if current date is past the expiry date
    };

    // ------------------------Filters ----------------------------------------
    useEffect(() => {
        console.log("Filters updated to: ", filters);
    }, [filters]);

    console.log(filters, "filters")
    const handleFilterChange = (field, value) => {
        setFilters(prev => ({ ...prev, [field]: value }));
        console.log("Updated Filters: ", filters);
    };
    const filteredPackages = useMemo(() => packages?.filter(pkg => {
        const filterStartDate = filters.startDate ? new Date(filters.startDate + 'T00:00:00') : null;
        const filterEndDate = filters.endDate ? new Date(filters.endDate + 'T23:59:59') : null;
        const startDate = new Date(pkg.dateDebut);
        const endDate = new Date(pkg.dateExp);
        const status = typeof calculateStatus(pkg.dateDebut, pkg.dateExp) === 'object' ? calculateStatus(pkg.dateDebut, pkg.dateExp).props.children : calculateStatus(pkg.dateDebut, pkg.dateExp);

        return (
            (pkg.username.toLowerCase().includes(filters.username.toLowerCase())) &&
            (!filterStartDate || startDate >= filterStartDate) &&
            (!filterEndDate || endDate <= filterEndDate) &&
            (!filters.status || status.toLowerCase().includes(filters.status.toLowerCase()))
        );
    }), [packages, filters]);



    // ------------------------END Filters -------------------------------------

    // ------------------------------ requete number------------------------------
    const allowedRequestsData = Array.isArray(query) ? query?.map(entry => entry?.totalAttacks) : [];  // Allowed requests data
    const attackData = Array.isArray(logReport?.filledTrafficData) ? logReport.filledTrafficData?.map(entry => entry.totalAttacks) : [];  // Attacks data

    const totalAllowedRequests = allowedRequestsData?.length > 0
        ? allowedRequestsData.reduce((acc, val) => acc + (val || 0), 0)
        : 0;

    const totalMaliciousRequests = attackData?.length > 0
        ? attackData.reduce((acc, val) => acc + (val || 0), 0)
        : 0;

    const totalReq = totalAllowedRequests + totalMaliciousRequests;
    console.log(totalReq, "totalReqtotalReq")


    // -------------------------------- Table ------------------------------------------
    // Styled TableCell
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#60b3a6',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    // Styled TableRow
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    // -------------------------------- END Table ------------------------------------------
    // ---------------------------------- get payments ------------------------------------
    // get payments 

    console.log(packages, "paymentList")


    // useEffect(() => {
    //     const fetchPayments = async () => {
    //         await dispatch(getAllPaymentsAdmin()); // Dispatch action to fetch all payments
    //     };
    //     fetchPayments(); // Fetch once on mount
    //     const interval = setInterval(() => {
    //         fetchPayments(); // Fetch periodically every 10 seconds
    //     }, 10000); // Adjust interval as needed
    //     return () => clearInterval(interval); // Cleanup on component unmount
    // }, [dispatch]);



    return (
        <>
            <TextField
                label="username"
                value={filters.username}
                onChange={(e) => handleFilterChange('username', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
            />
            <TextField
                label="Start Date"
                type="date"
                value={filters.startDate}
                onChange={(e) => handleFilterChange('startDate', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="End Date"
                type="date"
                value={filters.endDate}
                onChange={(e) => handleFilterChange('endDate', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    min: filters.startDate ? filters.startDate : undefined
                }}
                disabled={!filters.startDate} // Disable if no start date is selected
            />


            <Select
                label="Status"
                value={filters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
                displayEmpty
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8, width: 100 }}
            >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="expired">Expired</MenuItem>
                <MenuItem value="expiring soon">Expiring Soon</MenuItem>
            </Select>



            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow >
                            <StyledTableCell align="left" >User Name</StyledTableCell>
                            <StyledTableCell align="left">Email</StyledTableCell>
                            <StyledTableCell align="left">Phone Number</StyledTableCell>
                            <StyledTableCell align="left">Pack Name</StyledTableCell>
                            <StyledTableCell align="left">Payment Status</StyledTableCell>
                            <StyledTableCell align="left">Transaction Status</StyledTableCell>
                            <StyledTableCell align="left">Start Date:</StyledTableCell>
                            <StyledTableCell align="left">Expiry Date:</StyledTableCell>
                            <StyledTableCell align="left">Actions</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredPackages?.map((payment) => (
                            <StyledTableRow key={payment.packId} >
                                <StyledTableCell align="left">{payment.username}</StyledTableCell>
                                <StyledTableCell align="left">{payment.email}</StyledTableCell>
                                <StyledTableCell align="left">{payment.phoneNumber}</StyledTableCell>
                                <StyledTableCell align="left">{payment.packName}</StyledTableCell>
                                <StyledTableCell align="left">{payment.status === "failed" ? <>failed</> : <>{calculateStatus(payment.dateDebut, payment.dateExp)}</>}</StyledTableCell>
                                <StyledTableCell align="left">{payment.status}</StyledTableCell>
                                <StyledTableCell align="left">{formatDate(payment.dateDebut)}</StyledTableCell>
                                <StyledTableCell align="left">{formatDate(payment.dateExp)}</StyledTableCell>
                                <StyledTableCell
                                    align="center" // This ensures the buttons are horizontally centered in the cell
                                    style={{
                                        display: "flex",
                                        justifyContent: "center", // Centers the buttons horizontally within the cell
                                        alignItems: "center", // Centers the buttons vertically
                                        gap: "8px", // Adds some space between the buttons
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => onOpenDetails(payment)}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            padding: 0, // Removes default padding to make the button size exactly 40x40
                                        }}
                                    >
                                        <IconButton color="primary" style={{ width: "100%", height: "100%" }}>
                                            <Visibility />
                                        </IconButton>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleOpenUpdateModal(payment)}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            padding: 0, // Removes default padding to make the button size exactly 40x40
                                        }}
                                    >
                                        <IconButton color="secondary" style={{ width: "100%", height: "100%" }}>
                                            <Edit />
                                        </IconButton>
                                    </Button>

                                </StyledTableCell>
                            </StyledTableRow>

                        ))}

                    </TableBody>
                </Table>
                {selectedPackage && (
                    <UpdataTableIscriptionsAdmin
                        open={!!selectedPackage}
                        packageData={selectedPackage}
                        id={selectedPackage?._id}
                        onClose={handleCloseModal}
                        onSave={handleSaveUpdatedPackage}
                        formatDate={formatDate}
                    />
                )}

            </TableContainer>
        </>
    )
}

export default PackageTableAdmin
PackageTableAdmin.propTypes = {
    query: PropTypes.object,
    logReport: PropTypes.object,

};