import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import CustomizerReducer from './customizer/CustomizerSlice';
import userReducer from './apps/user/userSlice';
import { combineReducers } from 'redux';
import informationReducer from './apps/information/informationSlice';
import configurationReducer from './apps/configuration/configurationSlice';
import visitorsTraficReducer from './apps/traficVisitors/traficVisitorsSlice';
import modulesReducer from './apps/modules/moduleSlice';
import malwareReducer from './apps/malware/malwareSlice';
import banReducer from './apps/banAutomatique/banAutomatiqueSlice';
import logEntryReducer from './apps/logEntry/logEntrySlice';
import emailCheckReducer from './apps/emailCheck/emailCheckSlice';
import networkScanReducer from './apps/networkScan/networkScanSlice';
import dashboardReducer from './apps/dashboard/dashboradSlice';
import translateReducer from './apps/translateText/translateTextSlice';
import jiraReducer from './apps/jiraTicket/jiraTicketSlice';
import onboardingReducer from './apps/onboarding/onboardingSlice';
import packReducer from './apps/paimentMethod/packSlice'
import packModuleReducer from './apps/paimentMethod/packModuleSlice'
import inscrireAbonReducer from './apps/paimentMethod/inscrireAbonSlice';
import moduleListReducer from './apps/moduleList/moduleListSlice';
import  {paymentReducer}  from './apps/paimentMethod/paymentSlice';
import { transactionReducer } from './apps/paimentMethod/transactionSlice';
import { virementReducer } from './apps/paimentMethod/VirementSlice';
import patternReducer from './apps/pattern/patternSlice'

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['userReducer','informationReducer'], // Specify which reducers you don't want to persist
  blacklist: ["userReducer", "onboarding","dashboardReducer","configurationReducer"]
};

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  emailCheckReducer:emailCheckReducer,
  userReducer: userReducer,
  informationReducer:informationReducer,
  configurationReducer:configurationReducer,
  visitorsTraficReducer:visitorsTraficReducer,
  modulesReducer:modulesReducer,
  malwareReducer:malwareReducer,
  banReducer:banReducer,
  logEntryReducer:logEntryReducer,
  networkScanReducer:networkScanReducer,
  dashboardReducer:dashboardReducer,
  translateReducer:translateReducer,
  jiraReducer:jiraReducer,
  onboarding: onboardingReducer,
  patternReducer:patternReducer,
  packReducer: packReducer,
  packModuleReducer: packModuleReducer,
  inscrireAbonReducer: inscrireAbonReducer,
  moduleListReducer: moduleListReducer,
  payment: paymentReducer,
  transaction: transactionReducer,
  virement: virementReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);


