import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";

//add PACK
export const createModulePackage = createAsyncThunk("pack/add", async ({ pack, userRole }) => {
    try {
        let url = `/package/addModulePack?userRole=${userRole}`;
        const result = await Axios.post(url, pack);
        return result.data;
    } catch (error) {
        console.error(error);
        throw { message: error.response?.data?.msg || "An error occurred", status: error.response?.status };
    }
});
// Async thunk to fetch all packages
export const fetchAllPackagesModule = createAsyncThunk(
    "AllModulePackages/fetchAll",
    async (_, { rejectWithValue }) => {
        try {
            let url = `/package/getAllModulePackages`;
            let res = await Axios.get(url);
            console.log("Response from API:", res.data); // Debug API response
            return res.data;
        } catch (error) {
            console.error("Error fetching AllModulePackages:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

//del pack
export const deleteModulePackageById = createAsyncThunk("deleteModulePackageById/delete", async ({ id, userId, userRole }, { rejectWithValue }) => {
    try {
        // Construct the URL with query parameters
        let url = `/package/deleteModulePackageById?id=${id}&userId=${userId}&userRole=${userRole}`;
        let res = await Axios.delete(url);
        return res.data;
    } catch (error) {
        console.error("Error deleting ModulePackage:", error);
        return rejectWithValue(error.response?.data || "Something went wrong");
    }
});
export const updateModulePackagesbyId = createAsyncThunk(
    "packs/update",
    async ({ id,  updatedData, userRole }, { rejectWithValue }) => {
        try {
            // Construct the URL with query parameters
            let url = `/package/updateModulePackagesbyId?id=${id}&userRole=${userRole}`;
            let res = await Axios.put(url, updatedData);
            return res.data;
        } catch (error) {
            console.error("Error updating ModulePackages:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

const initialState = {
    packagesModules: [],
    status: null,
    error: null,
};

export const packModuleSlice = createSlice({
    name: "packsModule",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createModulePackage.pending, (state) => {
                state.status = "loading...";
                state.error = null;
            })
            .addCase(createModulePackage.fulfilled, (state, action) => {
                state.status = "success";
                state.packagesModules = action.payload?.packsModule || [];
            })
            .addCase(createModulePackage.rejected, (state, action) => {
                state.status = "fail";
                state.error = action.error.message;
            });
        // ----------------------- get Pack------------------
        builder
            .addCase(fetchAllPackagesModule.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllPackagesModule.fulfilled, (state, { payload }) => {
                // console.log("Fetched packagesModules:", action.payload);
                state.status = "succeeded";
                state.packagesModules = payload;

            })
            .addCase(fetchAllPackagesModule.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload?.msg || action.error.message || "Unknown error occurred";
            })
            // -----------------------DELETE packModule ----------------------

            .addCase(deleteModulePackageById.pending, (state) => {
                state.status = "pending";
            })
            .addCase(deleteModulePackageById.fulfilled, (state, action) => {
                state.status = "fulfilled";
                const deletedId = action.meta.arg.id;
                state.packagesModules = state.packagesModules.filter((pm) => pm._id !== deletedId);

            })
            .addCase(deleteModulePackageById.rejected, (state) => {
                state.status = "rejected";
            })
            // ----------------------- Update Pack ------------------
            .addCase(updateModulePackagesbyId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateModulePackagesbyId.fulfilled, (state, action) => {
                state.status = "succeeded";
                const updatedModule = action.payload.updatedPackageModule; // Match the backend response structure
                state.packagesModules = state.packagesModules.map((pm) =>
                    pm._id === updatedModule?._id ? updatedModule : pm
                );
            })
            .addCase(updateModulePackagesbyId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });

    },
});

export default packModuleSlice.reducer;
