import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    styled,
    TextField, tableCellClasses,
    Dialog, DialogTitle, DialogContent, Button,
    IconButton,
    useTheme,
    useMediaQuery,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { Visibility } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

const VirementTableAdmin = () => {
    // -------------------------------- Table ------------------------------------------
    // Styled TableCell
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#60b3a6',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding: 10
        },
    }));

    // Styled TableRow
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    // -------------------------------- END Table ------------------------------------------
    const [open, setOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    console.log(selectedTransaction, "selectedTransaction")
    const handleOpen = (transaction) => {
        setSelectedTransaction(transaction);
        setOpen(true);
    };
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
    };


    
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700, fontSize: "13px" }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>User Name</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Date de transaction</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Bank Name</StyledTableCell>
                            <StyledTableCell>Bank Country</StyledTableCell>
                            <StyledTableCell>Payment System</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Method</StyledTableCell>
                            <StyledTableCell>Sender Wallet</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                       
                            <StyledTableRow >
                                <StyledTableCell>N/A</StyledTableCell>                           
                                <StyledTableCell>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleOpen()}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            padding: 0, // Removes default padding to make the button size exactly 40x40
                                        }}
                                    >
                                        <IconButton color="primary" style={{ width: "100%", height: "100%" }}>
                                            <Visibility />
                                        </IconButton>
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                  
                    </TableBody>

                </Table>
            </TableContainer>
       
        </>
    )
}

export default VirementTableAdmin