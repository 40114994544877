import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";
import axios from 'axios';

// Create InscrireAbon
export const createInscrireAbon = createAsyncThunk(
    "inscrireAbon/create",
    async ({ inscrireAbon, userRole }) => {
        try {
            const url = `/inscrireAbon/addInscrireAbon?userRole=${userRole}`;
            const response = await Axios.post(url, inscrireAbon);
            return response.data;
        } catch (error) {
            console.error("Error creating InscrireAbon:", error);
            throw error; // Rethrow the error to handle it in the calling component
        }
    }
);
// Fetch all InscrireAbons
// Assuming you're now passing the userId to this function
export const getInscrireAbonsByUserId = createAsyncThunk(
    "InscrireAbons/fetchByUserId",
    async (userId, { rejectWithValue }) => {
        try {
            // Assuming your API endpoint now correctly expects a userId query parameter
            const response = await Axios.get(`/inscrireAbon/getInscrireAbonsByUserId?userId=${userId}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch inscrireAbons');
        }
    }
);


export const getAllInscrireAbonsAdmin = createAsyncThunk(
    "InscrireAbons/fetchAllAdmin",
    async (userRole, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/inscrireAbon/getAllInscrireAbonsAdmin?userRole=${userRole}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch inscrireAbons');
        }
    }
);

// Update InscrireAbon
export const updateInscrireAbon = createAsyncThunk(
    "inscrireAbon/update",
    async ({ id, userId, formData, userRole }, { rejectWithValue }) => {
        try {
            const url = `/inscrireAbon/updateInscrireAbonById?id=${id}&userRole=${userRole}`;
            const response = await Axios.put(url, formData);
            return response.data;
        } catch (error) {
            console.error("Error updating InscrireAbon:", error);
            return rejectWithValue(error.response?.data || "Something went wrong during the update.");
        }
    }
);

// Async thunk for sending a test email
export const sendNewInscriptionEmail = createAsyncThunk(
    'email/sendNewInscriptionEmail',
    async ({ email, username, packName,phoneNumber,Country,rqtNumber,priceV,priceC,nbrSite,siteName}, { rejectWithValue }) => {
        try {
            const url = `/inscrireAbon/sendNewInscriptionEmail`;
            console.log('Payload being sent:', { email, username,packName }); // Log payload
            const response = await Axios.post(url, {  email, username,phoneNumber,Country, packName,priceV,priceC,rqtNumber,nbrSite,siteName });
            console.log('Response from backend:', response.data); // Log backend response
            return response.data;
        } catch (error) {
            console.error('Error in sendNewInscriptionEmail:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || 'Error sending test email');
        }
    }
);

export const sendValidationInscriptionEmail = createAsyncThunk(
    'email/sendValidationInscriptionEmail',
    async ({ email, username, packName,phoneNumber,Country,rqtNumber,priceV,priceC,nbrSite,siteName}, { rejectWithValue }) => {
        try {
            const url = `/inscrireAbon/sendValidationInscriptionEmail`;
            console.log('Payload being sent:', { email, username,packName }); // Log payload
            const response = await Axios.post(url, {  email, username,phoneNumber,Country, packName,priceV,priceC,rqtNumber,nbrSite,siteName });
            console.log('Response from backend:', response.data); // Log backend response
            return response.data;
        } catch (error) {
            console.error('Error in sendValidationInscriptionEmail:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || 'Error sending test email');
        }
    }
);


// Initial State
const initialState = {
    inscrireAbons: [], // Holds the list of InscrireAbons
    status: null, // Status of the last operation
    error: null, // Any errors during operations
    isFetching: false, // Loading state
};

// Slice
const inscrireAbonSlice = createSlice({
    name: "inscrireAbons",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Create InscrireAbon Reducers
        builder
            .addCase(createInscrireAbon.pending, (state) => {
                state.status = "loading...";
                state.isFetching = true;
            })
            .addCase(createInscrireAbon.fulfilled, (state, action) => {
                state.status = "success";
                state.isFetching = false;
                state.inscrireAbons?.push(action.payload.newInscrireAbon); // Add new InscrireAbon to the list
            })
            .addCase(createInscrireAbon.rejected, (state, action) => {
                state.status = "failed";
                state.isFetching = false;
                state.error = action.error.message;
            })
            // Get All getInscrireAbonsByUserId
            .addCase(getInscrireAbonsByUserId.pending, (state) => {
                state.status = "loading...";
                state.isFetching = true;
            })
            .addCase(getInscrireAbonsByUserId.fulfilled, (state, { payload }) => {
                state.status = "succeeded";
                state.isFetching = false;
                state.inscrireAbons = payload || []; // Ensure state is fully replaced
                console.log("Updated Redux State (inscrireAbons):", state.inscrireAbons);
            })
            .addCase(getInscrireAbonsByUserId.rejected, (state, action) => {
                state.status = "failed";
                state.isFetching = false;
                state.inscrireAbons = [];
                state.error = action.error.message;
            })

            // Get All InscrireAbons ADMIN
            .addCase(getAllInscrireAbonsAdmin.pending, (state) => {
                state.status = "loading...";
                state.isFetching = true;
            })
            .addCase(getAllInscrireAbonsAdmin.fulfilled, (state, { payload }) => {
                state.status = "succeeded";
                state.isFetching = false;
                state.inscrireAbons = payload; // Here, ensure you're replacing or appending correctly based on your app's logic
                console.log("Updated Redux State (inscrireAbons):", state.inscrireAbons);
            })
            .addCase(getAllInscrireAbonsAdmin.rejected, (state, action) => {
                state.status = "failed";
                state.isFetching = false;
                state.error = action.error.message;
            })

            // update inscrire 
            // Add cases for updating an InscrireAbon in the existing extraReducers builder
            .addCase(updateInscrireAbon.pending, (state) => {
                state.status = "loading...";
                state.isFetching = true;
            })
            .addCase(updateInscrireAbon.fulfilled, (state, action) => {
                state.status = "success";
                state.isFetching = false;
                // Update the list of inscrireAbons with the updated entry
                const index = state.inscrireAbons.findIndex(abon => abon._id === action.payload.updatedInscrireAbon._id);
                if (index !== -1) {
                    state.inscrireAbons[index] = action.payload.updatedInscrireAbon;
                }
            })
            .addCase(updateInscrireAbon.rejected, (state, action) => {
                state.status = "failed";
                state.isFetching = false;
                state.error = action.error.message;
            })
            //sendNewInscriptionEmail 
            .addCase(sendNewInscriptionEmail.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(sendNewInscriptionEmail.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(sendNewInscriptionEmail.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            })
                  //sendValidationInscriptionEmail 
                  .addCase(sendValidationInscriptionEmail.pending, (state) => {
                    state.loading = true;
                    state.success = false;
                    state.error = null;
                })
                .addCase(sendValidationInscriptionEmail.fulfilled, (state) => {
                    state.loading = false;
                    state.success = true;
                    state.error = null;
                })
                .addCase(sendValidationInscriptionEmail.rejected, (state, action) => {
                    state.loading = false;
                    state.success = false;
                    state.error = action.payload;
                });
    },
});

// Export Reducer
export default inscrireAbonSlice.reducer;
