import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import PackageTableAdmin from "../../../components/apps/paimentMethod/PackageTableAdmin";
import PackageDetailsModalAdmin from "../../../components/apps/paimentMethod/PackageDetailsModalAdmin";
import { getAllPaymentsAdmin } from "../../../store/apps/paimentMethod/paymentSlice";
import TransactionTableAdmin from "../../../components/apps/paimentMethod/TransactionTableAdmin";

const TransactionAdmin = () => {
    const dispatch = useDispatch();
    // ------------------ end other imports -----------------------------

    // ------------------ UseSelector-------------------------------------
    // const { inscrireAbons, isFetching, error } = useSelector((state) => state.inscrireAbonReducer);
    const { users } = useSelector((state) => state.userReducer);
    const userId = users.id;
    // ------------------ endUseSelector-------------------------------------

    // ------------------ UseStates -------------------------------------

    const [open, setOpen] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    // ------------------ endUseStates -------------------------------------

    // ------------------ UseEffects -----------------------------------------

    useEffect(() => {
        dispatch(getAllPaymentsAdmin({userRole,userId}));
    }, [dispatch]);

    // ------------------ endUseEffects -------------------------------------
    //-------------------functions ------------------------------------------

    const handleOpenModal = (packageDetails) => {
        setSelectedPackage(packageDetails);
        setOpen(true);
    };
    const handleCloseModal = () => {
        setSelectedPackage(null);
        setOpen(false);
    };

    //------------------- end functions ------------------------------------------

    const userRole = users?.userRole;


    // useEffect(() => {
    //     dispatch(getAllPaymentsAdmin(userRole));
    // }, [dispatch]);


    const { paymentStatus, paymentList } = useSelector(state => state.payment);
    console.log(paymentList, "paymentListss")

    return (
        <>
            {users?.userRole === "Admin" ? <Box sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Package Inscription Administration
                </Typography>
                <TransactionTableAdmin
                    packages={paymentList}
                    onOpenDetails={handleOpenModal}
                />
                <PackageDetailsModalAdmin
                    open={open}
                    packageDetails={selectedPackage}
                    onClose={handleCloseModal}
                />
            </Box> :
                <Box>
                    <Typography variant="h5" gutterBottom style={{ color: 'red' }}>
                        You do not have permission to access this area
                    </Typography>

                </Box>
            }

        </>
    )
}

export default TransactionAdmin