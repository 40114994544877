import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

const PaymentFailure = () => {
    const [searchParams] = useSearchParams();
    const paymentRef = searchParams.get("payment_ref");
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/paimentMethod'); // Replace '/another-page' with the path you want to redirect to
    };
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            padding: '20px'
        }}>
            <CancelSharpIcon style={{ color: 'red', fontSize: "250px" }} />
            <h1 style={{ color: 'black', fontWeight: 'bold', fontSize: '32px', marginBottom: '20px' }}>
                Payment Failed
            </h1>
            <p style={{ color: 'black', fontSize: '18px', textAlign: 'center', marginBottom: '20px' }}>
                Your payment attempt has failed. Please try again.
            </p>
            <Button
                onClick={handleRedirect}
                sx={{
                    background: 'linear-gradient(45deg, rgb(64, 199, 181), rgb(62, 137, 218), rgb(64, 199, 181))',
                    color: 'transparent',
                    WebkitBackgroundClip: 'text',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    padding: '10px 20px',
                    textTransform: 'none',
                    border: '2px solid transparent',
                    marginTop: '20px'
                }}
                variant="contained"
            >
                Retry Payment
            </Button>
        </div>
    );
};

export default PaymentFailure;
