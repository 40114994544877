import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InscriptionTableClient from "../../../components/apps/paimentMethod/InscriptionTableClient";
import { Box, Typography } from "@mui/material";
import InscriptionDetailsClient from "../../../components/apps/paimentMethod/InscriptionDetailsClient";
import { getInscrireAbonsByUserId } from "../../../store/apps/paimentMethod/inscrireAbonSlice";
import { useTranslation } from 'react-i18next';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import { getPaymentsByUserId } from "../../../store/apps/paimentMethod/paymentSlice";

const InscriptionClient = () => {

    // ------------------ other imports ---------------------------------
    const dispatch = useDispatch();
    // ------------------ end other imports -----------------------------

    // ------------------ UseSelector-------------------------------------
    // const { inscrireAbons, isFetching, error } = useSelector((state) => state.inscrireAbonReducer);
    // console.log(inscrireAbons,"inscrireAbons")
    const { users } = useSelector((state) => state.userReducer);
    const userId = users.id
    // ------------------ endUseSelector-------------------------------------

    // ------------------ UseStates -------------------------------------
    const [open, setOpen] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);


    // ------------------ endUseStates -------------------------------------

    // ------------------ UseEffects -----------------------------------------


    // ------------------ endUseEffects -------------------------------------
    //-------------------functions ------------------------------------------
       
    useEffect(() => {
        dispatch(getPaymentsByUserId(userId));
    }, [dispatch]);


    const {  paymentListUser } = useSelector(state => state.payment);
    useEffect(() => {
        dispatch(getPaymentsByUserId(userId));
    }, [dispatch]);
console.log(paymentListUser,"paymentListUser")
    const handleOpenModal = (packageDetails) => {
        setSelectedPackage(packageDetails);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedPackage(null);
        setOpen(false);
    };
    //------------------- end functions ------------------------------------------
    const userRole = users?.userRole;


    // ----------------------TRANSLATIONS ---------------------------------------
    const { t, i18n } = useTranslation()

    const currentLanguage = i18n.language;
    const textsToTranslate = {
        client_insc: 'Client Inscriptions',
    };
    const translatedTexts = useTranslateTexts(textsToTranslate);

    // ----------------------TRANSLATIONS ---------------------------------------


    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>
            {translatedTexts?.client_insc}
            </Typography>
            <InscriptionTableClient
                inscrireAbons={paymentListUser}
              
                onOpenDetails={handleOpenModal}
            />
            <InscriptionDetailsClient
                open={open}
                packageDetails={selectedPackage}
                onClose={handleCloseModal}
            />
        </Box>
    )
}

export default InscriptionClient