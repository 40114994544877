/* eslint-disable react/prop-types */
import React, { useEffect ,useMemo} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Box,
    Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTranslation } from "react-i18next";
import useTranslateTexts from "../../../Context/useTranslateTexts";
import { fetchLogEntryAggregates, getLogEntryAggregateClient } from "../../../store/apps/logEntry/logEntrySlice";

const PackageDetailsModalAdmin = ({ open, packageDetails, onClose }) => {
    // Retrieve all available modules from Redux state
    const { packagesModules } = useSelector((state) => state.packModuleReducer);

    // Function to get module names by matching IDs
    // Function to get module names by matching IDs
    const getModuleNamesByIds = (moduleIds) => {
        if (!moduleIds || moduleIds.length === 0) return "N/A";

        const matchedModules = packagesModules.filter(module =>
            moduleIds.includes(module._id)
        );
        return matchedModules.map(module => module.name).join(", ");
    };
    const getTotalSites = () => {
        if (!packageDetails || !packageDetails?.site) return 0;
        // If `site` is an array, calculate unique site names
        if (Array.isArray(packageDetails?.site)) {
            const uniqueSites = new Set(packageDetails?.site.map((site) => site?.site_name));
            return uniqueSites.size;
        }

        // If `site` is a single object
        return packageDetails?.site?.site_name ? 1 : 0;
    };

    const totalSites = getTotalSites();
    const { sitesList, users } = useSelector(state => state.userReducer);

    const siteId = sitesList?._id;
    const { trafficData, logAggregates } = useSelector((state) => state.logEntryReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        if (siteId) {
            dispatch(getLogEntryAggregateClient(siteId)); // Correct dispatching
            dispatch(fetchLogEntryAggregates(siteId)); // Correct dispatching
        }
    }, [dispatch, siteId]);


    // Assuming packageDetails.site can be an array or a single object.
    const matchingSites = packageDetails?.site
        ? (Array.isArray(packageDetails.site)
            ? packageDetails.site.filter(siteDetail =>
                sitesList?.some(site => site._id === siteDetail.site_id))
            : sitesList?.some(site => site._id === packageDetails.site.site_id)
                ? [packageDetails.site]
                : [])
        : [];

    // Perform calculations only if `trafficData` and `logAggregates` are defined
    const totalTrafficData = trafficData?.totalTrafficData || 0;
    const totalLogAggregates = logAggregates?.totalTrafficData || 0;
    const totalRequestsForMatchingSites = totalTrafficData + totalLogAggregates;
    // ------------------------------------------------ traduction -------------------------
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const direction = currentLanguage === "ar" ? "rtl" : "ltr";
    const textsToTranslate = {
        pack_det: "Package Details",
        pack_name: "Package Name:",
        total_site: "Total Sites:",
        pack_price: "Price :",
        sites: "Sites",
        nbr_site: "Number of sites",
        pack_rqt_nmbr: "Pack Rqt Number:",
        unlimited: "Unlimited",
        pack_Type: "Pack Type:",
        pack_modules: "Modules",
    };
    const dynamicKeys = useMemo(() => {
        const keys = {};
        if (packageDetails) {
            keys[`packName_${packageDetails._id}`] = packageDetails.packName;
            keys[`packType_${packageDetails._id}`] = packageDetails.packType;

            // Add keys for modules
            packageDetails.packModule?.forEach((module) => {
                keys[`module_${module._id}`] = module.name;
            });
        }
        return keys;
    }, [packageDetails]);

    // Translate texts dynamically
    const translatedTexts = useTranslateTexts({ ...textsToTranslate, ...dynamicKeys });
    // -------------------- TRANSLATIONS -------------------------------------
    function formatRequests(count) {
        // If you want to treat -1 as "unlimited", do that separately.
        // Otherwise, handle only numeric formatting here.
        if (count >= 1_000_000) {
            return (count / 1_000_000).toFixed(0) + ' M';
        } else if (count >= 1_000) {
            return (count / 1_000).toFixed(0) + ' k';
        }
        return count.toString();
    }
    return (
        // <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        //     <DialogTitle>Package Details</DialogTitle>
        //     <DialogContent>
        //         {packageDetails ? (
        //             <>
        //                 <DialogContentText>
        //                     <strong>Pack Name:</strong> {packageDetails.packName}
        //                 </DialogContentText>
        //                 <DialogContentText>
        //                     <strong>Sites:</strong>{" "}
        //                     {Array.isArray(packageDetails.site)
        //                         ? packageDetails.site.map((site, index) => (
        //                             <span key={site.site_id}>
        //                                 {site.site_name}
        //                                 {index < packageDetails.site.length - 1 && ", "} {/* Add comma between names */}
        //                             </span>
        //                         ))
        //                         : packageDetails.site?.site_name || "N/A"}
        //                 </DialogContentText>

        //                 <DialogContentText>
        //                     <strong>Total Sites:</strong> {totalSites}/{packageDetails.packNbrSite}
        //                 </DialogContentText>
        //                 <DialogContentText>
        //                     <strong>Pack Price (Value):</strong> {packageDetails.amount}
        //                 </DialogContentText>
        //                 <DialogContentText>
        //                     <strong>Pack Price (Currency):</strong> {packageDetails.token}
        //                 </DialogContentText>

        //                 <DialogContentText>
        //                     <strong>Pack Rqt Number:</strong>{" "}
        //                     {packageDetails.packRqtNumber === -1 ? <>Unlimited</> : <>{matchingSites.length > 0 ? <>{totalRequestsForMatchingSites}/{packageDetails.packRqtNumber}</> : <></>}</>}
        //                 </DialogContentText>
        //                 <DialogContentText>
        //                     <strong>Pack Type:</strong> {packageDetails.packType}
        //                 </DialogContentText>


        //                 <DialogContentText>
        //                     <strong>Pack Modules:</strong> {packageDetails.packModule.map((module) => module.name).join(", ")}
        //                 </DialogContentText>

        //             </>
        //         ) : (
        //             "No Details Available"
        //         )}
        //     </DialogContent>
        //     <DialogActions>
        //         <Button onClick={onClose} color="primary" variant="contained">
        //             Close
        //         </Button>
        //     </DialogActions>
        // </Dialog>

        <Dialog open={open} onClose={onClose} maxWidth="md" dir={direction}>
            <Box sx={{ maxWidth: '700px', backgroundColor: "#d9d9d94a" }} >
                {/* <DialogTitle>{translatedTexts?.pack_det}</DialogTitle> */}
                <DialogContent >
                    {packageDetails ? (
                        <>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <DialogContentText sx={{ fontSize: "24px", fontWeight: 800, color: "linear-gradient(45deg, #40c7b5, #3e89da, #40c7b5)" }}>
                                    {translatedTexts[`packName_${packageDetails._id}`] || packageDetails.packName}
                                </DialogContentText>
                                <DialogContentText sx={{ fontSize: "20px", fontWeight: 600 }}>
                                    <p style={{ margin: "0", fontWeight: 400 }}>{(translatedTexts?.pack_price)} {(packageDetails.amount).toLocaleString('de-DE')}{" "}
                                        {packageDetails.token}</p>
                                </DialogContentText>
                            </Box>
                            <hr></hr>
                            <Box>
                                <DialogContentText>
                                    <strong>{translatedTexts?.sites}</strong>{" "}
                                    {Array.isArray(packageDetails.site)
                                        ? packageDetails.site.map((site, index) => (
                                            <span key={site.site_id}>
                                                {site.site_name}
                                                {index < packageDetails.site.length - 1 && ", "}
                                            </span>
                                        ))
                                        : packageDetails.site?.site_name || "N/A"}
                                </DialogContentText>

                                <DialogContentText>
                                    <strong>{translatedTexts?.total_site}</strong> {totalSites}/{packageDetails.packNbrSite}
                                </DialogContentText>
                                <DialogContentText>
                                    <strong>Periode : </strong>
                                    {packageDetails.packPeriod === "365 jours" ? 'annuel' : 'mensuel'}
                                </DialogContentText>

                                <DialogContentText>
                                    <strong>{translatedTexts?.pack_rqt_nmbr}</strong>{" "}
                                    {packageDetails.packRqtNumber === -1
                                        ? translatedTexts?.unlimited
                                        : formatRequests(packageDetails.packRqtNumber)}
                                </DialogContentText>


                                {/* <DialogContentText>
                        <strong>{translatedTexts.pack_Type}</strong>{" "}
                        {translatedTexts[`packType_${packageDetails._id}`] || packageDetails.packType}
                    </DialogContentText> */}
                            </Box>

                            <hr></hr>
                            <Box sx={{ width: "100%" }}>
                                {/* Heading */}
                                <Typography variant="h6" sx={{ mb: 1, fontSize: "24px", color: "linear-gradient(45deg, #40c7b5, #3e89da, #40c7b5)" }}>
                                    {translatedTexts.pack_modules}
                                </Typography>

                                {/* Grid container for bullet items */}
                                <Box
                                    sx={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(4, 1fr)", // 4 columns; adjust to your needs
                                        rowGap: 1, // vertical space between rows
                                        columnGap: 2, // horizontal space between columns
                                    }}
                                >
                                    {packageDetails.packModule.map((mod) => {
                                        const moduleName = translatedTexts[`module_${mod._id}`] || mod.name;
                                        return (
                                            <Box
                                                key={mod._id}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    // optional styling
                                                }}
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{ fontSize: "0.8rem", mr: 1 }}
                                                />
                                                <Typography variant="body2">{moduleName}</Typography>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </>
                    ) : (
                        "No Details Available"
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default PackageDetailsModalAdmin;
