/* eslint-disable prefer-destructuring */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Axios } from "../../../config";

// Define the async thunk for initializing payment
export const initializePayment = createAsyncThunk(
    'payment/initializePayment',
    async (paymentDetails, { rejectWithValue }) => {
        try {
            
            const userId = paymentDetails.userId;
            const siteId = paymentDetails.site.site_id;
            let url = `/payments/init-payment?userId=${userId}&siteId=${siteId}`;
            const response = await Axios.post(url, paymentDetails);
            const result = response.data.data;
            console.log(result, "result")
            window.location.href = result.payUrl;
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
// Fetch payment by ID
export const getPaymentById = createAsyncThunk(
    'payment/getPaymentById',
    async ({paymentRef,userId}, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/payments/get-payment-byid?userId=${userId}`, {
                params: { payment_ref: paymentRef,
                    userId: userId     
                 }  // Pass payment_ref as a query parameter
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Fetch all payments
export const getAllPaymentsAdmin = createAsyncThunk(
    "Payments/fetchAllAdmin",
    async ({userRole,userId}, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/payments/getAllPaymentsAdmin?userRole=${userRole}&userId=${userId}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch Payment');
        }
    }
);


// In handlePaymentWebhook
export const handlePaymentWebhook = createAsyncThunk(
    'payment/handlePaymentWebhook',
    async (webhookData, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await Axios.post('/payments/webhook', webhookData); // Destructure response.data
            const { status, paymentRef, user, pack, site } = data; // Destructure properties from response data
            console.log(status, "statusstatusstatus")
            // Check payment status
            if (status === 'completed') {
                // If payment is successful, dispatch email
                await dispatch(sendValidationInscriptionEmail({
                    email: user.email,
                    username: user.username,
                    packName: pack.name,
                    phoneNumber: user.phoneNumber,
                    Country: user.Country,
                    rqtNumber: pack.rqt_number === -1 ? "Unlimited" : pack.rqt_number.toString(),
                    priceV: pack.price.value,
                    priceC: pack.price.currency,
                    nbrSite: pack.nombre_site,
                    siteName: site.url,
                }));
            }

            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


// Update Payments
export const updatePaymentById = createAsyncThunk(
    "payment/update",
    async ({ id, userId, formData, userRole }, { rejectWithValue }) => {
        try {
            const url = `/payments/updatePaymentById?id=${id}&userRole=${userRole}`;
            const response = await Axios.put(url, formData);
            return response.data;
        } catch (error) {
            console.error("Error updating Payments:", error);
            return rejectWithValue(error.response?.data || "Something went wrong during the update.");
        }
    }
);

// get payment for every client by user id
export const getPaymentsByUserId = createAsyncThunk(
    "payments/fetchByUserId",
    async (userId, { rejectWithValue }) => {
        try {
            // Assuming your API endpoint now correctly expects a userId query parameter
            const response = await Axios.get(`/payments/getPaymentsByUserId?id=${userId}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch Payment');
        }
    }
);


// -------------------- emails ------------------------------
export const sendValidationInscriptionEmail = createAsyncThunk(
    'email/sendValidationInscriptionEmail',
    async ({ email, username, packName, phoneNumber, Country, rqtNumber, priceV, priceC, nbrSite, siteName }, { rejectWithValue }) => {
        try {
            const url = `/payments/sendValidationInscriptionEmail`;
            console.log('Payload being sent:', { email, username, packName }); // Log payload
            const response = await Axios.post(url, { email, username, phoneNumber, Country, packName, priceV, priceC, rqtNumber, nbrSite, siteName });
            console.log('Response from backend:', response.data); // Log backend response
            return response.data;
        } catch (error) {
            console.error('Error in sendValidationInscriptionEmail:', error.response?.data || error.message);
            return rejectWithValue(error.response?.data || 'Error sending test email');
        }
    }
);

export const createFacture = createAsyncThunk(
    'payment/createFacture',
    async (paymentData, { rejectWithValue }) => {
        try {
            const response = await Axios.post('/factures/payment-success', paymentData); // Send payment data to backend
            return response.data; // Response will be { message: 'Facture created successfully' }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// -----------------------------------------------------------
// Async thunk for sending payment details via email
export const sendFactureEmail = createAsyncThunk(
    'payment/sendFactureEmail',
    async ({ email, username, phoneNumber, packName, paymentRef, amount, token, userId, siteName, packModule, dateExp }, { rejectWithValue }) => {
        try {
            const url = `/payments/sendFactureEmail?userId=${userId}`; // Include userId in the URL query
            const response = await Axios.post(url, {
                email,
                username,
                phoneNumber,
                packName,
                paymentRef,
                amount,
                token,
                siteName,
                packModule,
                dateExp
            });
            console.log(response, "email data")
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const saveTransaction = createAsyncThunk(
    'payment/saveTransaction',
    async ({ paymentRef, transactionData }, { rejectWithValue }) => {
        // Check if there is any transaction data to save
        if (!transactionData || transactionData.length === 0) {
            console.log("No transaction data to save.");
            return rejectWithValue('No transaction data to save');
        }

        try {
            const response = await Axios.put(`/payments/updatePaymentByRef?payment_ref=${paymentRef}`, {
                transactionData
            });

            return response.data;
        } catch (error) {
            console.error("Error saving transaction:", error);
            return rejectWithValue(error.response?.data || 'Error saving transaction.');
        }
    }
);




const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        paymentList: [],
        paymentListUser: [],
        paymentData: null,
        paymentRef: null, // To store the paymentRef
        paymentStatus: 'idle',
        error: null,
        transactionSaved: false,
    },
    reducers: {
        setTransactionSaved: (state, action) => {
            state.transactionSaved = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Initialize Payment
            .addCase(initializePayment.pending, (state) => {
                state.paymentStatus = 'loading';
            })
            .addCase(initializePayment.fulfilled, (state, action) => {
                state.paymentStatus = 'succeeded';
                state.paymentData = action.payload;
                state.paymentRef = action.payload.data.paymentRef;
                

            })
            .addCase(initializePayment.rejected, (state, action) => {
                state.paymentStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(createFacture.fulfilled, (state, action) => {
                console.log('Facture created:', action.payload);
            })
            .addCase(createFacture.rejected, (state, action) => {
                console.error('Error creating facture:', action.payload);
            })
            // Get Payment By ID
            .addCase(getPaymentById.pending, (state) => {
                state.paymentStatus = 'loading';
            })
            .addCase(getPaymentById.fulfilled, (state, action) => {
                state.paymentStatus = 'succeeded';
                state.paymentData = action.payload.data.payment;
            })
            .addCase(getPaymentById.rejected, (state, action) => {
                state.paymentStatus = 'failed';
                state.error = action.payload;
            })

            // Get All Payments
            .addCase(getAllPaymentsAdmin.pending, (state) => {
                state.paymentStatus = 'loading';
            })
            .addCase(getAllPaymentsAdmin.fulfilled, (state, { payload }) => {
                state.paymentStatus = 'succeeded';
                state.paymentList = payload;  // The list of all payments
            })

            .addCase(getAllPaymentsAdmin.rejected, (state, action) => {
                state.paymentStatus = 'failed';
                state.paymentList = [];  // The list of all payments

                state.error = action.payload || 'Failed to fetch payments. Please try again later.';
            })
            // Get All getPaymentsByUserId
            .addCase(getPaymentsByUserId.pending, (state) => {
                state.status = "loading...";
                state.isFetching = true;
            })
            .addCase(getPaymentsByUserId.fulfilled, (state, { payload }) => {
                state.status = "succeeded";
                state.isFetching = false;
                state.paymentListUser = payload || []; // Ensure state is fully replaced
                console.log("Updated Redux State (Payment):", state.payments);
            })
            .addCase(getPaymentsByUserId.rejected, (state, action) => {
                state.status = "failed";
                state.isFetching = false;
                state.paymentListUser = [];
                state.error = action.error.message;
            })

            // Handle Payment Webhook
            .addCase(handlePaymentWebhook.pending, (state) => {
                state.paymentStatus = 'loading';
            })
            .addCase(handlePaymentWebhook.fulfilled, (state, action) => {
                state.paymentStatus = 'succeeded';
                state.webhookResponse = action.payload;
            })
            .addCase(handlePaymentWebhook.rejected, (state, action) => {
                state.paymentStatus = 'failed';
                state.error = action.payload;
            })

            // Update Payment
            .addCase(updatePaymentById.pending, (state) => {
                state.paymentStatus = 'loading';
            })
            .addCase(updatePaymentById.fulfilled, (state, action) => {
                // Update the list of payments with the updated entry
                const index = state.paymentList?.findIndex(payment => payment?._id === action?.payload?.updatedPayment?._id);
                if (index !== -1) {
                    state.paymentList[index] = action.payload.updatedPayment;
                }
            })
            .addCase(updatePaymentById.rejected, (state, action) => {
                state.paymentStatus = 'failed';
                state.error = action.payload;
            })
            // ----------------- emails ------------------------
            //sendValidationInscriptionEmail 
            .addCase(sendValidationInscriptionEmail.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = null;
            })
            .addCase(sendValidationInscriptionEmail.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(sendValidationInscriptionEmail.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            })
            // ------------------------ facture par email -------------------------
            .addCase(sendFactureEmail.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendFactureEmail.fulfilled, (state, action) => {
                state.isLoading = false;
                console.log("Email sent successfully!");
            })
            .addCase(sendFactureEmail.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                console.error("Error sending email:", action.payload);
            })
            // Handle successful transaction saving
            .addCase(saveTransaction.fulfilled, (state, action) => {
                // Update the state to reflect that the transaction has been saved
                state.isSaved = true;
                state.isActive = true;
                state.paymentData.transactions?.push(action.payload.transactionData);
            })
            .addCase(saveTransaction.rejected, (state, action) => {
                // Handle case where the save operation was rejected
                state.error = action.payload;
            });
    }
});

export const { actions: paymentActions, reducer: paymentReducer } = paymentSlice;
