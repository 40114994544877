import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Axios } from "../../../config";
import { sendValidationInscriptionEmail } from './inscrireAbonSlice';


export const initializeTransaction = createAsyncThunk(
    'transactions/initialize',
    async (transactionData, { rejectWithValue }) => {
        try {
            let url = `/transaction/initialize`;
            const response = await Axios.post(url, transactionData);
            const result = response.data.data;
            console.log(result, "result")
            window.location.href = result.payUrl;
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
  );

  export const handleTransactionWebhook = createAsyncThunk(
      'payment/handlePaymentWebhook',
      async (webhookData, { rejectWithValue, dispatch }) => {
          try {
              const { data } = await Axios.post('/payments/webhook', webhookData); // Destructure response.data
              const { status, paymentRef, user, pack, site } = data; // Destructure properties from response data
              console.log(status, "statusstatusstatus")
              // Check payment status
              if (status === 'completed') {
                  // If payment is successful, dispatch email
                  await dispatch(sendValidationInscriptionEmail({
                      email: user.email,
                      username: user.username,
                      packName: pack.name,
                      phoneNumber: user.phoneNumber,
                      Country: user.Country,
                      rqtNumber: pack.rqt_number === -1 ? "Unlimited" : pack.rqt_number.toString(),
                      priceV: pack.price.value,
                      priceC: pack.price.currency,
                      nbrSite: pack.nombre_site,
                      siteName: site.url,
                  }));
              }
  
              return data;
          } catch (error) {
              return rejectWithValue(error.response.data);
          }
      }
  );
  
  export const updateTransactionStatus = createAsyncThunk(
    'transactions/updateStatus',
    async ({ paymentRef, status }, { rejectWithValue }) => {
        console.log(paymentRef,"paymentRefs")
      try {
        const response = await axios.patch(`http://localhost:5000/api/transaction/${paymentRef}/update-status`, { status });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  


export const fetchAndCreateTransaction = createAsyncThunk(
    'transactions/fetchAndCreate',
    async (paymentRef, { rejectWithValue }) => {
      try {
        const response = await axios.get(`http://localhost:5000/transaction/create?payment_ref=${paymentRef}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const saveTransactionData = createAsyncThunk(
    'transactions/saveData',
    async (transactionData, { rejectWithValue }) => {
        try {
            const response = await axios.post('http://localhost:5000/api/transaction/create-transaction', transactionData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        items: [],
        transactionList: [],
        transactionListUser: [],
        transactionData: null,
        transactionRef: null, // To store the transactionRef
        transactionStatus: 'idle',
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAndCreateTransaction.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchAndCreateTransaction.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.data = action.payload.data; // assuming the API returns the transaction under a 'data' key
        })
        .addCase(fetchAndCreateTransaction.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        })
        .addCase(saveTransactionData.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(saveTransactionData.fulfilled, (state, action) => {
            state.loading = false;
            // state.transactions?.push(action.payload);
        })
        .addCase(saveTransactionData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || 'Failed to save transaction';
        })

        .addCase(initializeTransaction.pending, (state) => {
            state.loading = true;
          })
          .addCase(initializeTransaction.fulfilled, (state, action) => {
            state.items?.push(action.payload);
            state.loading = false;
          })
          .addCase(initializeTransaction.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
          })
          builder.addCase(updateTransactionStatus.pending, (state) => {
            state.loading = true;
          });
          builder.addCase(updateTransactionStatus.fulfilled, (state, action) => {
            const index = state.items.findIndex(item => item.paymentRef === action.meta.arg.paymentRef);
            if (index !== -1) {
              state.items[index] = action.payload.data;
            }
            state.loading = false;
          });
          builder.addCase(updateTransactionStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message || 'Failed to update transaction';
          });
    }
    
  });

export const { actions: transactionActions, reducer: transactionReducer } = transactionSlice;
