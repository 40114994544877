/* eslint-disable prefer-destructuring */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Axios } from "../../../config";


// Fetch all virements
export const getAllVirementsAdmin = createAsyncThunk(
    "Virements/fetchAllAdmin",
    async ({userRole}, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`/virement/getAllVirmentsAdmin?userRole=${userRole}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch Virement');
        }
    }
);


const virementSlice = createSlice({
    name: 'virement',
    initialState: {
        virementList: [],
        virementListUser: [],
        virementData: null,
        virementRef: null, // To store the virementRef
        virementStatus: 'idle',
        error: null,
        transactionSaved: false,  
    },
    reducers: {
        setTransactionSaved: (state, action) => {
            state.transactionSaved = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Get All Virements
            .addCase(getAllVirementsAdmin.pending, (state) => {
                state.virementStatus = 'loading';
            })
            .addCase(getAllVirementsAdmin.fulfilled, (state, { payload }) => {
                state.virementStatus = 'succeeded';
                state.virementList = payload;  // The list of all virements
            })

            .addCase(getAllVirementsAdmin.rejected, (state, action) => {
                state.virementStatus = 'failed';
                state.virementList = [];  // The list of all virements
                state.error = action.payload || 'Failed to fetch virements. Please try again later.';
            })
           
    }
});

export const { actions: virementActions, reducer: virementReducer } = virementSlice;
