import React, { useMemo, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    styled,
    TextField, tableCellClasses,
    Dialog, DialogTitle, DialogContent, Button,
    IconButton
} from '@mui/material';
import PropTypes from 'prop-types';
import { Visibility } from '@mui/icons-material';
import useTranslateTexts from '../../../Context/useTranslateTexts';
import { useTranslation } from 'react-i18next';


const TransactionTableClient = ({ inscrireAbons, onOpenDetails }) => {
    const [filters, setFilters] = useState({
        username: '',
        startDate: '',
        endDate: '',
        status: '',
    });
    console.log(inscrireAbons, "packages")
    const handleFilterChange = (field, value) => {
        setFilters(prev => ({ ...prev, [field]: value }));
    };

    const formatDate = date => {
        if (!date) return '';
        const d = new Date(date);
        let month = `${d.getMonth() + 1}`;
        let day = `${d.getDate()}`;
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return `${month}/${day}/${year}`;
    };

    const filteredPackages = inscrireAbons?.filter(pkg => {
        const filterStartDate = filters.startDate ? new Date(filters.startDate + 'T00:00:00') : null;
        const filterEndDate = filters.endDate ? new Date(filters.endDate + 'T23:59:59') : null;
        const startDate = new Date(pkg.dateDebut);
        const endDate = new Date(pkg.dateExp);

        return (
            (!filters.username || pkg.username.toLowerCase().includes(filters.username.toLowerCase())) &&
            (!filterStartDate || startDate >= filterStartDate) &&
            (!filterEndDate || endDate <= filterEndDate)
        );
    });


    // -------------------------------- Table ------------------------------------------
    // Styled TableCell
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#60b3a6',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding: 10
        },
    }));

    // Styled TableRow
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    // -------------------------------- END Table ------------------------------------------


    const [open, setOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    console.log(selectedTransaction, "selectedTransaction")
    const handleOpen = (transaction) => {
        setSelectedTransaction(transaction);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // ---------------------------- TRADUCTION ---------------------------
    // ------------------------- DYNAMIQUE TRADUCTION --------------------------
    const generateTranslationKeys = useMemo(() => {
        const keys = {};
        inscrireAbons?.forEach(abon => {
            keys[`username_${abon._id}`] = abon.username;  // This is correct.
            keys[`email_${abon._id}`] = abon.email;  // This is correct.
            keys[`status_${abon._id}`] = abon.status;  // This is correct.
            keys[`bankName_${abon._id}`] = abon.transactions[0]?.extSenderInfo?.bankInfo?.bankName;  // This is correct.
            keys[`bankCountryName_${abon._id}`] = abon.transactions[0]?.extSenderInfo?.bankInfo?.bankCountryName;  // This is correct.
            keys[`paymentSystem_${abon._id}`] = abon.transactions[0]?.extSenderInfo?.paymentSystem;  // This is correct.
            keys[`type_${abon._id}`] = abon.transactions[0]?.type;  // This is correct.
            keys[`method_${abon._id}`] = abon.transactions[0]?.method;  // This is correct.
            // **********************************Transaction details*************************************
            keys[`trName_${abon._id}`] = abon.transactions[0]?.extSenderInfo?.name;  // This is correct.
            keys[`trEmail_${abon._id}`] = abon.transactions[0]?.extSenderInfo?.email;  // This is correct.
            keys[`pkg_name_${abon._id}`] = abon.packName;  // This is correct.

            console.log(keys[`statusName_${abon._id}`], "keystatus")
        });
        return keys;
    }, [inscrireAbons]);
    const translatedUserName = useTranslateTexts(generateTranslationKeys);
    const translatedEmail = useTranslateTexts(generateTranslationKeys);
    const translatedStatus = useTranslateTexts(generateTranslationKeys);
    const translatedBankName = useTranslateTexts(generateTranslationKeys);
    const translatedbankCountryName = useTranslateTexts(generateTranslationKeys);
    const translatedSystem = useTranslateTexts(generateTranslationKeys);
    const translatedType = useTranslateTexts(generateTranslationKeys);
    const translatedMethod = useTranslateTexts(generateTranslationKeys);
    const translatedTrName = useTranslateTexts(generateTranslationKeys);
    const translatedTrEmail = useTranslateTexts(generateTranslationKeys);
    const translatedPkgName = useTranslateTexts(generateTranslationKeys);

    console.log(translatedUserName, "formatUsernames")

    // ---------------------- STATIQUE TRADUCTION ----------------------------
    const { t, i18n } = useTranslation()
    const isRtl = i18n.language === 'ar';

    const currentLanguage = i18n.language;
    const textsToTranslate = {
        userName: 'UserName',
        email: "Email",
        status: "Status",
        transac_date: "Date de transaction",
        Amount: "Amount",
        bank_name: "Bank Name",
        bank_country: "Bank Country",
        pay_system: "Payment System",
        type: "Type",
        Method: "Method",
        sender_wallet: "Sender Wallet",
        action: "Actions",
        konnect: "Konnect",
        pkg_name: "Package",
        ph_number: "PhoneNumber",
        period: "Period",
        tran_detail: "Transaction Details",
        mensuel: "Mensuel",
        annuel: "Annuel"


    };
    const translatedTexts = useTranslateTexts(textsToTranslate);


    return (
        <>
            <TextField
                label="Username"
                value={filters.username}
                onChange={e => handleFilterChange('username', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
            />
            <TextField
                label="Start Date"
                type="date"
                value={filters.startDate}
                onChange={e => handleFilterChange('startDate', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="End Date"
                type="date"
                value={filters.endDate}
                onChange={e => handleFilterChange('endDate', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    min: filters.startDate ? filters.startDate : undefined,
                }}
                disabled={!filters.startDate}
            />
            <TextField
                label="Status"
                value={filters.status}
                onChange={e => handleFilterChange('status', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
            />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700, fontSize: "13px" }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{translatedTexts?.userName}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.email}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.status}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.transac_date}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.Amount}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.bank_name}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.bank_country}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.pay_system}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.type}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.Method}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.sender_wallet}</StyledTableCell>
                            <StyledTableCell>{translatedTexts?.action}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredPackages.map((payment, index) => (
                            <StyledTableRow key={payment.packId}>
                                <StyledTableCell>{translatedUserName[`username_${payment._id}`] || payment.username} {/* Translated name or fallback */}
                                </StyledTableCell>
                                <StyledTableCell>{translatedEmail[`email_${payment._id}`] || payment.email}</StyledTableCell>
                                <StyledTableCell>{translatedStatus[`status_${payment._id}`] || payment.status || 'N/A'}</StyledTableCell>
                                <StyledTableCell>{formatDate(payment.createdAt) || 'N/A'}</StyledTableCell>
                                {payment.transactions && payment.transactions.length > 0 ? (
                                    <>
                                        <StyledTableCell>
                                            {`${(payment.amount).toLocaleString('de-DE') || 'N/A'} ${payment.transactions[0].token || 'N/A'}`}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {translatedBankName[`bankName_${payment._id}`] || payment.transactions[0]?.extSenderInfo?.bankInfo?.bankName || 'N/A'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {translatedbankCountryName[`bankCountryName_${payment._id}`] || payment.transactions[0]?.extSenderInfo?.bankInfo?.bankCountryName || 'N/A'}


                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {translatedSystem[`paymentSystem_${payment._id}`] || payment.transactions[0]?.extSenderInfo?.paymentSystem || 'N/A'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {translatedType[`type_${payment._id}`] || payment.transactions[0]?.type || 'N/A'}

                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {translatedMethod[`method_${payment._id}`] || payment.transactions[0]?.method || 'N/A'}

                                        </StyledTableCell>
                                        {/* <StyledTableCell>{payment.transactions[0].status || 'N/A'}</StyledTableCell> */}
                                        <StyledTableCell>
                                            {payment?.payUrl?.includes('konnect') ? `${translatedTexts.konnect}` : 'N/A'}
                                        </StyledTableCell>
                                    </>
                                ) : (
                                    // If no transactions exist
                                    <>
                                        <StyledTableCell colSpan={7}>N/A</StyledTableCell>
                                    </>
                                )}

                                <StyledTableCell>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleOpen(payment)}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            padding: 0, // Removes default padding to make the button size exactly 40x40
                                        }}
                                    >
                                        <IconButton color="primary" style={{ width: "100%", height: "100%" }}>
                                            <Visibility />
                                        </IconButton>
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            {selectedTransaction && (
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="transaction-details-dialog"
                    style={{ direction: isRtl ? 'rtl' : 'ltr' }}  // Apply RTL if Arabic
                >
                    <DialogTitle>{translatedTexts.tran_detail}</DialogTitle>
                    <DialogContent>
                        <p>{translatedTexts?.userName} : {translatedTrName[`trName_${selectedTransaction._id}`] || selectedTransaction.transactions[0]?.extSenderInfo?.name || 'N/A'}</p>
                        <p>{translatedTexts?.email} : {translatedTrEmail[`trEmail_${selectedTransaction._id}`] || selectedTransaction.transactions[0]?.extSenderInfo?.email || 'N/A'}</p>
                        <p>{translatedTexts?.pkg_name} : {translatedPkgName[`pkg_name_${selectedTransaction._id}`] || selectedTransaction.packName || 'N/A'}</p>
                        <p>{translatedTexts?.ph_number} : {selectedTransaction.phoneNumber}</p>
                        <p>{translatedTexts?.period} : {selectedTransaction.packPeriod === "365 jours" ? translatedTexts.mensuel : translatedTexts.annuel}
                        </p>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};


TransactionTableClient.propTypes = {
    inscrireAbons: PropTypes.array.isRequired,
    onOpenDetails: PropTypes.func,
};

export default TransactionTableClient;