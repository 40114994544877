import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    styled,
    TextField, tableCellClasses,
    Dialog, DialogTitle, DialogContent, Button,
    IconButton,
    useTheme,
    useMediaQuery,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { Visibility } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

const TransactionTableAdmin = ({ packages, onOpenDetails }) => {
    const [filters, setFilters] = useState({
        username: '',
        startDate: '',
        endDate: '',
        status: '',
    });
    console.log(packages, "packages")
    const handleFilterChange = (field, value) => {
        setFilters(prev => ({ ...prev, [field]: value }));
    };

    const formatDate = date => {
        if (!date) return '';
        const d = new Date(date);
        let month = `${d.getMonth() + 1}`;
        let day = `${d.getDate()}`;
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return `${month}/${day}/${year}`;
    };

    const filteredPackages = packages?.filter(pkg => {
        const filterStartDate = filters.startDate ? new Date(filters.startDate + 'T00:00:00') : null;
        const filterEndDate = filters.endDate ? new Date(filters.endDate + 'T23:59:59') : null;
        const startDate = new Date(pkg.dateDebut);
        const endDate = new Date(pkg.dateExp);

        return (
            (!filters.username || pkg.username.toLowerCase().includes(filters.username.toLowerCase())) &&
            (!filterStartDate || startDate >= filterStartDate) &&
            (!filterEndDate || endDate <= filterEndDate)
        );
    });


    // -------------------------------- Table ------------------------------------------
    // Styled TableCell
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#60b3a6',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding: 10
        },
    }));

    // Styled TableRow
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    // -------------------------------- END Table ------------------------------------------

    const [open, setOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    console.log(selectedTransaction, "selectedTransaction")
    const handleOpen = (transaction) => {
        setSelectedTransaction(transaction);
        setOpen(true);
    };
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <TextField
                label="Username"
                value={filters.username}
                onChange={e => handleFilterChange('username', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
            />
            <TextField
                label="Start Date"
                type="date"
                value={filters.startDate}
                onChange={e => handleFilterChange('startDate', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="End Date"
                type="date"
                value={filters.endDate}
                onChange={e => handleFilterChange('endDate', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    min: filters.startDate ? filters.startDate : undefined,
                }}
                disabled={!filters.startDate}
            />
            <TextField
                label="Status"
                value={filters.status}
                onChange={e => handleFilterChange('status', e.target.value)}
                variant="outlined"
                style={{ marginRight: 8, marginBottom: 8 }}
            />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700, fontSize: "13px" }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>User Name</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Date de transaction</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Bank Name</StyledTableCell>
                            <StyledTableCell>Bank Country</StyledTableCell>
                            <StyledTableCell>Payment System</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Method</StyledTableCell>
                            <StyledTableCell>Sender Wallet</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredPackages.map((payment, index) => (
                            <StyledTableRow key={payment.packId}>
                                <StyledTableCell>{payment.username || 'N/A'}</StyledTableCell>
                                <StyledTableCell>{payment.email || 'N/A'}</StyledTableCell>
                                <StyledTableCell>{payment.status || 'N/A'}</StyledTableCell>
                                <StyledTableCell>{formatDate(payment.createdAt) || 'N/A'}</StyledTableCell>

                                {payment.transactions && payment.transactions.length > 0 ? (
                                    <>
                                        <StyledTableCell>
                                            {`${(payment.amount).toLocaleString('de-DE') || 'N/A'} ${payment.transactions[0].token || 'N/A'}`}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {payment.transactions[0]?.extSenderInfo?.bankInfo?.bankName || 'N/A'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {payment.transactions[0]?.extSenderInfo?.bankInfo?.bankCountryName || 'N/A'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {payment.transactions[0]?.extSenderInfo?.paymentSystem || 'N/A'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {payment.transactions[0]?.type || 'N/A'}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {payment.transactions[0]?.method || 'N/A'}
                                        </StyledTableCell>
                                        {/* <StyledTableCell>{payment.transactions[0].status || 'N/A'}</StyledTableCell> */}
                                        <StyledTableCell>
                                            {payment?.payUrl?.includes('konnect') ? 'Konnect' : 'N/A'}
                                        </StyledTableCell>
                                    </>
                                ) : (
                                    // If no transactions exist
                                    <>
                                        <StyledTableCell colSpan={7}>N/A</StyledTableCell>
                                    </>
                                )}

                                <StyledTableCell>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleOpen(payment)}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            padding: 0, // Removes default padding to make the button size exactly 40x40
                                        }}
                                    >
                                        <IconButton color="primary" style={{ width: "100%", height: "100%" }}>
                                            <Visibility />
                                        </IconButton>
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            {selectedTransaction && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullScreen={fullScreen}
                    aria-labelledby="transaction-details-dialog"
                >
                    <DialogTitle id="transaction-details-dialog">
                        Transaction Details
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1" gutterBottom>
                            UserName: {selectedTransaction.transactions[0]?.extSenderInfo?.name}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                           Email: {selectedTransaction.transactions[0]?.extSenderInfo?.email}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Package: {selectedTransaction.packName}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            PhoneNumber: {selectedTransaction.phoneNumber}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Period: {selectedTransaction.packPeriod === "365 jours" ? 'Mensuel' : 'Annuel'}
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

TransactionTableAdmin.propTypes = {
    packages: PropTypes.array.isRequired,
    onOpenDetails: PropTypes.func,
};

export default TransactionTableAdmin;
