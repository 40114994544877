import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import PackageTableAdmin from "../../../components/apps/paimentMethod/PackageTableAdmin";
import PackageDetailsModalAdmin from "../../../components/apps/paimentMethod/PackageDetailsModalAdmin";
import TransactionTableAdmin from "../../../components/apps/paimentMethod/TransactionTableAdmin";
import VirementTableAdmin from "../../../components/apps/paimentMethod/VirementTableAdmin";
import { getAllVirementsAdmin } from "../../../store/apps/paimentMethod/VirementSlice";
import { getSiteUserList, getuserbyid } from "../../../store/apps/user/userSlice";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { fetchPackageById } from "../../../store/apps/paimentMethod/packSlice";

const VirementAdmin = () => {
    const dispatch = useDispatch();
    // ------------------ end other imports -----------------------------

    // ------------------ UseSelector-------------------------------------
    // const { inscrireAbons, isFetching, error } = useSelector((state) => state.inscrireAbonReducer);
    const { users } = useSelector((state) => state.userReducer);
    const { virementList } = useSelector(state => state.virement);
    const { packages, status, error } = useSelector((state) => state.packReducer);
    const [emailsByUserId, setEmailsByUserId] = useState({}); 
    const [userNameByUserId, setuserNameByUserId] = useState({}); 
    console.log(userNameByUserId, "userNameByUserId")
    const [localVirement, setLocalVirement] = useState([]);
    const [virementLists, setVirementLists] = useState([]);

    const [packNameBypackId, setPackNameBypackId] = useState({}); 
    const [packPeriodeBypackId, setPackPeriodBypackId] = useState({}); 

    console.log(packPeriodeBypackId, "packNameBypackId")
    // const [localVirement, setLocalVirement] = useState([]);
    // const [virementLists, setVirementLists] = useState([]);
    console.log(virementList, "virementList")
    const userId = users.id;
    // ------------------ endUseSelector-------------------------------------

    // ------------------ UseStates -------------------------------------

    const [open, setOpen] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    // ------------------ endUseStates -------------------------------------

    // ------------------ UseEffects -----------------------------------------

    useEffect(() => {
        dispatch(getAllVirementsAdmin({ userRole, userId }));
    }, [dispatch]);

    // ------------------ endUseEffects -------------------------------------
    //-------------------functions ------------------------------------------

    const handleOpenModal = (packageDetails) => {
        setSelectedPackage(packageDetails);
        setOpen(true);
    };
    const handleCloseModal = () => {
        setSelectedPackage(null);
        setOpen(false);
    };

    //------------------- end functions ------------------------------------------

    const userRole = users?.userRole;
    // const userId=users.id

    useEffect(() => {
        dispatch(fetchPackageById({ userId, }));
    }, [dispatch]);


    const { paymentStatus, paymentList } = useSelector(state => state.payment);
    console.log(paymentList, "paymentListss")

    useEffect(() => {
        const fetchData = async () => {
            let response = await dispatch(getAllVirementsAdmin({ userRole: 'Admin', userId: userId })); // Adjust parameters as needed
            console.log('Response:', response);
            if (response?.payload) {
                // Mettre à jour les logs avec la payload paginée et filtrée
                setVirementLists(response.payload);
            }
        }

        fetchData();


    }, [dispatch]);

    // useEffect(() => {
    //     virementList.forEach(virement => {
    //         if (!users[virement.userId]) {
    //             dispatch(getuserbyid(virement.userId));
    //         }
    //     });
    // }, [dispatch]);
    console.log('dataaaaaaaaaa', virementLists)
    useEffect(() => {
        // Charger la liste des sites
        dispatch(getAllVirementsAdmin({ userRole: 'Admin', userId: userId })).then((action) => {
            if (action.payload) {
                setLocalVirement(action.payload);
                console.log('virrrrr', localVirement)
                // Récupérer les emails des utilisateurs en fonction de userId
                action.payload.forEach(vir => {
                    if (vir.userId) {
                        dispatch(getuserbyid(vir.userId)).then((userAction) => {
                            if (userAction.payload && userAction.payload.email) {
                                setEmailsByUserId(prev => ({
                                    ...prev,
                                    [vir.userId]: userAction.payload.email
                                }));
                            }
                            if (userAction.payload && userAction.payload.username) {
                                setuserNameByUserId(prev => ({
                                    ...prev,
                                    [vir.userId]: userAction.payload.username
                                }));
                            }
                        });
                    }                    
                    if (vir?.packId) {
                        dispatch(fetchPackageById({ id: vir.packId })).then((packAction) => {
                            console.log('packaction', packAction)
                            if (packAction.payload && packAction.payload.name) {
                                console.log('paccc', packAction.payload)
                                setPackNameBypackId(prev => ({
                                    ...prev,
                                    [vir.packId]: packAction?.payload?.name
                                }));
                                console.log(vir, "virrrr")
                            }
                            if (packAction.payload && packAction.payload.period) {
                                console.log('paccc', packAction.payload.period)
                                setPackPeriodBypackId(prev => ({
                                    ...prev,
                                    [vir.packId]: packAction?.payload?.period
                                }));
                                console.log(vir, "virrrr")
                            }
                        });
                    }
                    
                });
            }
        });
    }, [dispatch]);
    console.log('mail', packNameBypackId)
    const data2 = virementLists?.length > 0
        ? virementLists?.map((data, key) => {
            return {
                id: data?._id,
                userId: data?.userId,
                packId: data?.packId
            };
        })

        : [];

    console.log('data', data2)
    // useEffect(() => {
    //     virementList.forEach(virement => {
    //         if (!packages.some(p => p._id === virement.packId)) {
    //             dispatch(fetchPackageById(virement.packId));
    //         }
    //     });
    // }, [virementList, virementList, dispatch]);
    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Email Utilisateur',
            accessor: 'userId',
            minWidth: 200,  // Largeur minimale de la colonne

            Cell: row => (
                <div>{emailsByUserId[row.value] || 'Email non disponible'}</div>
            ),
        },
        {
            Header: 'userName',
            accessor: 'userId',
            minWidth: 200,  // Largeur minimale de la colonne

            Cell: row => (
                <div>{userNameByUserId[row.value] || 'username non disponible'}</div>
            ),
        },
        {
            Header: 'Pack Utilisateur',
            accessor: 'packId',
            minWidth: 200,  // Largeur minimale de la colonne

            Cell: row => (
                <div>{packNameBypackId[row.value] || 'pack non disponible'}</div>
            ),
        },

        {
            Header: 'Pack period',
            accessor: 'packId',
            minWidth: 200,  // Largeur minimale de la colonne
            Cell: row => (
                <div>{packPeriodeBypackId[row.value] || 'period non disponible'}</div>
            ),
        },


    ]
    return (
        <>
            {users?.userRole === "Admin" ? <Box sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Package Inscription Administration
                </Typography>
                <VirementTableAdmin
                    packages={paymentList}
                    onOpenDetails={handleOpenModal}
                />
                <PackageDetailsModalAdmin
                    open={open}
                    packageDetails={selectedPackage}
                    onClose={handleCloseModal}
                />
            </Box> :
                <Box>
                    <Typography variant="h5" gutterBottom style={{ color: 'red' }}>
                        You do not have permission to access this area
                    </Typography>

                </Box>
            }
            <div>
                <ReactTable
                    data={data2}
                    showPaginationBottom
                    columns={columns}
                    defaultPageSize={10}
                    className="-striped -highlight"

                />
            </div>
            {/* {data2?.map((em)=><>{em}</>)} */}

        </>)
}

export default VirementAdmin