import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Axios } from "../../../config";


//get all PACK
// Async thunk to fetch all moduleLists
export const fetchAllModuleList = createAsyncThunk(
    "moduleLists/fetchAll",
    async (_, { rejectWithValue }) => {
        try {
            let url = `/moduleList/getAllmoduleLists`;
            let res = await Axios.get(url);
            console.log("res", res);
            return res.data;
        } catch (error) {
            console.error("Error fetching moduleLists:", error);
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);


const initialState = {
    moduleLists: [], // Initialized as an empty array
    status: null,
    error: null,
    isFetchingModuleLists: false,
};
export const packSlice = createSlice({
    name: "moduleLists",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //add packs extra reducers
        builder
            // ----------------------- get Pack------------------
            .addCase(fetchAllModuleList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllModuleList.fulfilled, (state, { payload }) => {
                // console.log("Fetched modules:", action.payload);
                state.status = "succeeded";
                state.moduleLists = payload;
                state.isFetchingModuleLists = true;

            })
            .addCase(fetchAllModuleList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload; // Store the error message
            })

         
    },


});

export default packSlice.reducer;